import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Modal from '../../core/Modal';
import { formatCurrency } from '../../utils/currency';
import { getTotalBilling } from './utils';

function ConfirmationDialog({ open, onCancel, onConfirm }) {
  const form = useFormikContext();
  const [checked, setChecked] = useState(false);
  const total = formatCurrency(getTotalBilling(form));

  if (!form.values.amount) return null;

  function handleConfirm() {
    onConfirm(form.values, form);
  }

  function handleClose() {
    form.setSubmitting(false);
    onCancel();
  }

  return (
    <Modal open={open} onClose={handleClose} includeCloseButton={false}>
      <h1 className="text-xl">Please confirm your payment to proceed</h1>
      <div className="my-4">
        <label
          htmlFor="confirmation"
          className="text-gray-600 text-sm text-semibold"
        >
          <input
            type="checkbox"
            className="mr-2"
            id="confirmation"
            checked={checked}
            onChange={() => setChecked(!checked)}
          ></input>
          Yes, I'd like to confirm immediate payment of{' '}
          <strong>{total} dollars</strong> for an annual subscription.
        </label>
      </div>

      <div className="flex justify-between">
        <button className="button small minimal" onClick={handleClose}>
          Cancel
        </button>
        <button
          className="button small primary"
          disabled={!checked}
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  total: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmationDialog;
