import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';

import Popup from '../Popup';

function Tooltip({ children, render, className }) {
  const [isOpen, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  const toRender = isString(render)
    ? () => <div dangerouslySetInnerHTML={{ __html: render }} />
    : () => render;

  return (
    <div
      className={`inline ${className}`}
      onMouseEnter={open}
      onTouchStart={open}
      onMouseLeave={close}
      onTouchEnd={close}
    >
      <Popup isOpen={isOpen} render={toRender}>
        {children}
      </Popup>
    </div>
  );
}

Tooltip.propTypes = {
  className: PropTypes.string,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired
};

export default Tooltip;
