import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loading: false,
      success: false,
      dialog: this.props.dialog
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.proceed = this.proceed.bind(this);
  }

  proceed(e) {
    e.preventDefault();

    const {
      method,
      action,
      token,
    } = this.props;

    if (this.props.mode == 'openNewTab') {
      window.open(action);
    } else {
      this.setState({ loading: true });

      axios({
        method,
        url: action,
        data: {
          authenticity_token: token,
        },
      }).then(({ data }) => {
        if (this.props.mode == 'waitResponse') {
          this.setState({ loading: true });
          window.location.reload();
        } else if (this.props.mode == 'loadFromServer') {
          this.setState({ loading: false, success: true, dialog: data.result });
        }
      }).catch(() => {
        this.setState({ loading: true });
        window.location.reload();
      });
    }
  }

  close(e) {
    e.preventDefault();
    this.setState({ isOpen: false, loading: false, success: false, dialog: this.props.dialog });
  }

  open(e) {
    e.preventDefault();
    this.setState({ isOpen: true });
  }

  render() {
    const {
      value,
      dialog,
      successMessage,
      size,
      classes,
    } = this.props;

    return (
      <span ref={(el) => { this.el = el; }}>
        <button className={classes} onClick={this.open}>
          {value}
        </button>
        <Modal
          appElement={this.el}
          className={`modal modal-dialog ${size}`}
          overlayClassName="overlay"
          isOpen={this.state.isOpen}
        >
          <div className="center pb2" dangerouslySetInnerHTML={{ __html: this.state.dialog }}></div>
          {
            !this.state.success &&
            <div className="actions">
              <button
                onClick={this.proceed}
                disabled={this.state.loading}
                className="btn btn-pill btn-primary mr1"
              >
                {this.state.loading &&
                  <ClipLoader
                    color="#fff"
                    size={16}
                  />
                }
                {this.props.proceedButton}
              </button>
              <button
                onClick={this.close}
                className="btn btn-pill btn-secondary ml1"
              >
                {this.props.cancelButton}
              </button>
            </div>
          }
          {
            this.state.success &&
            <div className="actions">
              <button
                onClick={this.close}
                className="btn btn-pill btn-primary m0"
              >
                Ok
              </button>
            </div>
          }
        </Modal>
      </span>
    );
  }
}

ModalDialog.propTypes = {
  value: PropTypes.string.isRequired,
  dialog: PropTypes.string.isRequired,
  method: PropTypes.string,
  classes: PropTypes.string,
  token: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  proceedButton: PropTypes.string,
  cancelButton: PropTypes.string,
  successMessage: PropTypes.string,
  size: PropTypes.string,
  mode: PropTypes.string
};

ModalDialog.defaultProps = {
  classes: '',
  method: 'GET',
  proceedButton: 'PROCEED',
  cancelButton: 'CANCEL',
  size: ''
};

export default ModalDialog;
