import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

import { isRequired, isEmail } from '../../../../validations';

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      email: '',
      error: {},
      usernameLoading: false,
      registerDisabled: false,
      buttonCopy: props.buttonCopy || 'REGISTER'
    };

    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onUsernameBlur = this.onUsernameBlur.bind(this);
  }

  onRegisterClick(event) {
    event.preventDefault();

    if (this.areInputsValid()) {
      axios
        .post('/api/v1/users', {
          authenticity_token: this.props.authenticityToken,
          user: { ...this.state,
                  skip_standard_email: true,
                  registered_via: 'GENETIC_WIZARD' }
        })
        .then(({ data }) => {
          if (data.error) {
            this.setState({ error: data.error });
          } else {
            this.props.onSubmit(data.session_token, this.state.email);
          }
        })
        .catch(() => {
          this.setState({
            username: '',
            password: '',
            email: '',
            usernameLoading: false,
            error: {
              base: 'Something went wrong. Please, try again.'
            }
          });
        });
    }
  }

  onUsernameBlur({ target }) {
    const { value } = target;

    if (value !== '') {
      this.setState({ usernameLoading: true, error: { username: '' } });

      axios
        .get('/username_validation', {
          params: { username: value }
        })
        .then(({ data }) => {
          if (!data.isUsernameValid) {
            this.setState({
              error: { username: 'Username is invalid.' }
            });
          }

          this.setState({ usernameLoading: false });
        });
    }
  }

  areInputsValid() {
    const { username, email, password } = this.state;
    const error = {
      email: isRequired(email) || isEmail(email),
      username: isRequired(username),
      password: isRequired(password)
    };

    const result = Object.values(error).filter(e => e).length === 0;

    this.setState({ error, registerDisabled: !result });

    return result;
  }

  render() {
    return (
      <div
        id="wizard-registration"
        className="flex flex-column"
        style={{ height: '100%' }}
      >
        <h4>Register</h4>

        {this.state.error.base && (
          <div className="center error p1">{this.state.error.base}</div>
        )}

        <div className="mb2">
          <form>
            <input
              id="wizard-registration-username"
              onChange={({ target, isTrusted }) => {
                if (!isTrusted) {
                  return;
                }
                this.setState({ username: target.value });
                this.areInputsValid();
              }}
              name="wizard-registration-username"
              onBlur={this.onUsernameBlur}
              value={this.state.username}
              type="text"
              autoComplete="off"
              placeholder="Enter Username"
            />
          </form>

          {this.state.usernameLoading && (
            <div className="loading">
              <ClipLoader color="#0e7afc" size={12} />{' '}
              <span className="small color-muted">
                Verifying username availability...
              </span>
            </div>
          )}

          {this.state.error.username && (
            <div className="error">{this.state.error.username}</div>
          )}
        </div>

        <div className="mb2">
          <form>
            <input
              id="wizard-registration-email"
              onChange={e => {
                this.setState({ email: e.target.value });
                this.areInputsValid();
              }}
              value={this.state.email}
              type="email"
              autoComplete={false}
              placeholder="Enter Email"
            />

            {this.state.error.email && (
              <div className="error">{this.state.error.email}</div>
            )}

            {this.props.emailHint && (
              <div className="p-1 small italic error">
                Did you mean{' '}
                <a
                  className="cursor-pointer underline not-italic"
                  onClick={() => this.setState({ email: this.props.emailHint })}
                >
                  {this.props.emailHint}
                </a>{' '}
                ?
              </div>
            )}
          </form>
        </div>

        <div className="mb2">
          <form>
            <input
              onChange={e => {
                this.setState({
                  password: e.target.value
                });
                this.areInputsValid();
              }}
              value={this.state.password}
              id="wizard-registration-password"
              autoComplete="new-password"
              disabled={this.state.disabled}
              type="password"
              placeholder="Enter Password"
            />

            {this.state.error.password && (
              <div className="error">{this.state.error.password}</div>
            )}
          </form>
        </div>

        <p className="lower-text">
          Register an account and gain greater ability to manage reports you
          have created, participate in the
          <img
            alt="foundmyfitness-text-logo"
            src="/images/horizontal_transparent.png"
          />
          community, and receive periodic updates that are relevant to you.
        </p>

        <div
          className="flex-auto position-relative mb2"
          style={{ height: '3rem' }}
        >
          <button
            type="button"
            onClick={this.onRegisterClick}
            className="btn btn-pill btn-block btn-primary position-absolute"
            disabled={this.state.registerDisabled}
            style={{ bottom: 0 }}
          >
            {this.props.buttonCopy || (
              <i
                className="fa fa-spinner fa-pulse fa-fw"
                aria-hidden="true"
              ></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SignUpForm;
