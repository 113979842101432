import React from 'react';
import PropTypes from 'prop-types';
import { CardElement } from 'react-stripe-elements';

const wrapper = {
  padding: '.5rem 1.25rem',
  border: '1px solid #cacaca',
  borderRadius: '10rem',
};

const styles = {
  base: {
    fontSize: '16px',
    lineHeight: '1.5rem',
  },
};

const CreditCard = ({ hidePostalCode = false }) => (
  <div style={wrapper} id="wizard-payment-card">
    <CardElement style={styles} hidePostalCode={hidePostalCode} />
  </div>
);

CreditCard.propTypes = {
  hidePostalCode: PropTypes.bool
}

export default CreditCard;
