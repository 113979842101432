import React from 'react';
import { shallow } from 'enzyme';
import { Field as FormikField, ErrorMessage } from 'formik';

import Field from './Field';

describe('<Field />', () => {
  let wrapper;
  let props;

  const defaultProps = {
    name: 'email'
  };

  function setup(overProps = {}) {
    props = {
      ...defaultProps,
      ...overProps
    };

    wrapper = shallow(<Field {...props} />);
  }

  describe('when rendering with no errors', () => {
    beforeAll(() => {
      setup();
      wrapper = wrapper.find(FormikField).renderProp('children')({
        field: {},
        form: { errors: {}, touched: {} }
      });
    });

    it('exists', () => {
      expect(wrapper).toExist();
    });
  });

  describe('when rendering errors', () => {
    const errorMsg = 'Is invalid';
    let errorWrapper;
    let fieldWrapper;

    beforeAll(() => {
      setup();
      fieldWrapper = wrapper.find(FormikField).renderProp('children')({
        field: {},
        form: { errors: { email: errorMsg }, touched: { email: true } }
      });
      errorWrapper = fieldWrapper.find(ErrorMessage).renderProp('children')(
        'Is invalid'
      );
    });

    it('renders input with error className', () => {
      expect(fieldWrapper.find('input')).toHaveProp('className', 'field error');
    });

    it('renders error message', () => {
      expect(errorWrapper).toHaveText('Is invalid');
    });
  });
});
