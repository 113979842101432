import { useFormikContext } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { SUBMIT_COPY, SUBSCRIPTION_TYPE } from './config';
import { formatCurrency } from '../../utils/currency';
import { getTotalBilling } from './utils';

export function useSubmitButtonProps(submitCopy, confirmed) {
  const form = useFormikContext();

  if (form.isSubmitting) {
    return {
      disabled: true,
      children: SUBMIT_COPY.SUBMITTING()
    };
  }

  const amount = formatCurrency(getTotalBilling(form));
  const frequencyCopy = {
    [SUBSCRIPTION_TYPE.ANNUAL]: 'yearly',
    [SUBSCRIPTION_TYPE.MONTHLY]: 'monthly'
  }[form.values.donationType];

  return {
    disabled: !confirmed,
    children: submitCopy ?? SUBMIT_COPY.SUBSCRIBE(amount, frequencyCopy)
  };
}

export function useFetchStripeToken() {
  const stripe = useStripe();
  const elements = useElements();

  return async () => {
    const cardElement = elements.getElement(CardElement);
    const { error, token: stripeToken } = await stripe.createToken(cardElement);

    if (error) {
      return { error: error.message };
    }

    return {
      zipCode:      stripeToken.card.address_zip,
      stripeToken:  stripeToken.id
    };
  };
}
