import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isRequired, isEmail } from '../../../../validations';

const CREATE_SESSION_PATH = '/api/v1/sessions';

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: {}
    };

    this.onLoginClick = this.onLoginClick.bind(this);
  }

  onLoginClick(e) {
    e.preventDefault();
    if (this.areInputsValid()) {
      axios
        .post(CREATE_SESSION_PATH, { session: this.state })
        .then(({ data }) => {
          if (data.error) {
            this.setState({ error: { authentication: data.error } });
          } else {
            const sessionToken = data.session_token;
            const isSupporter = data['subscriber?'];
            const { email } = data;

            this.setState({ error: {} });
            this.props.onSubmit(sessionToken, email, isSupporter);
          }
        })
        .catch(() => {
          this.setState({
            error: { base: 'Something went wrong. Please try again.' }
          });
        });
    }
  }

  areInputsValid() {
    const { email, password } = this.state;

    const error = {
      email: isRequired(email),
      password: isRequired(password)
    };

    this.setState({ error });

    return Object.values(error).filter(e => e).length === 0;
  }

  render() {
    return (
      <div className="flex flex-column" style={{ height: '100%' }}>
        <h4>Sign In</h4>

        {this.state.error.base && (
          <div className="center error p1">{this.state.error.base}</div>
        )}

        {this.state.error &&
          this.state.error.authentication && (
            <div className="error center">
              {this.state.error.authentication}
            </div>
          )}

        <div className="mb2">
          <input
            id="wizard-login-email"
            autoComplete="off"
            onChange={e => this.setState({ email: e.target.value })}
            placeholder="Enter your email"
            value={this.state.email}
            type="email"
          />

          {this.state.error &&
            this.state.error.email && (
              <div className="error">{this.state.error.email}</div>
            )
          }
        </div>

        <div className="mb2">
          <input
            id="wizard-login-password"
            onChange={e =>
              this.setState({
                password: e.target.value
              })
            }
            autoComplete="off"
            placeholder="Enter your password"
            type="password"
            value={this.state.password}
          />

          {this.state.error &&
            this.state.error.password && (
              <div className="error">{this.state.error.password}</div>
            )}
        </div>

        <p className="lower-text">
          Already have a
          <img
            alt="foundmyfitness-text-logo"
            src="/images/horizontal_transparent.png"
          />
          account?
          <br />
          Just login to get started.
        </p>

        <div
          className="flex-auto position-relative mb2"
          style={{ height: '3rem' }}
        >
          <button
            type="button"
            onClick={this.onLoginClick}
            className="btn btn-pill btn-block btn-primary position-absolute"
            style={{ bottom: 0 }}
          >
            SIGN IN
          </button>
        </div>
      </div>
    );
  }
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SignInForm;
