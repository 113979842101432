import React from 'react';

import PremiumSubscriptionForm from '../../views/PremiumSubscriptionForm';

import Modal from '../Modal';

const SubscriptionModal = ({
  premiumSubscriptionFormProps,
  header,
  description,
  submitCopy,
  source,
  isOpen,
  onClose,
  onSuccess
}) => {
  return (
    <Modal open={isOpen} includeCloseButton={true} onClose={onClose}>
      <>
        <PremiumSubscriptionForm
          {...premiumSubscriptionFormProps}
          header={header}
          description={description}
          highlightedAmounts={[25000]}
          compact
          source={source}
          submitCopy={submitCopy}
          onSuccess={onSuccess}
        />
        <div className="mx-auto text-sm text-center">
          <a href="/crowdsponsor" className="text-gray-600" target="_blank">
            Learn more about premium membership benefits
          </a>
        </div>
      </>
    </Modal>
  );
};

export default SubscriptionModal;
