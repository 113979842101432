import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OpenGeneticReportWizard from '../OpenGeneticReportWizard';
import Modal from '../../core/Modal';

export default function ChangelogModal({ content, isOpen = false, openGeneticReportWizardProps = {} }) {
  const [open, setOpen] = useState(isOpen);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        typer="button"
        className="appearance-none underline"
      >
        view changelog
      </button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div
          className="changelog-modal"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="flex justify-center items-center changelog-modal-footer">
          <OpenGeneticReportWizard
            innerHTML="<div class='button primary-alternative'>Run New Report</div>"
            wizardState={{ ...openGeneticReportWizardProps }}
          />
        </div>
      </Modal>
    </>
  );
}

ChangelogModal.propTypes = {
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  openGeneticReportWizardProps: PropTypes.object
};
