import React from 'react';

const ReportDescription = ({ name, details, minimumAmount, onAccept }) => {
  return (
    <div>
      {minimumAmount == 0 && <h3>Try a sample genetic report for free</h3>}
      <h4 style={{textAlign: 'left'}}>{name}</h4>
      <div dangerouslySetInnerHTML={{ __html: details }}></div>

      <div className="center mt2">
        <button onClick={() => onAccept()} className="btn btn-pill btn-primary mr2">Try it out</button>
        <button onClick={() => window.location = '/genetics'} className="btn btn-pill btn-secondary">not now</button>
      </div>
    </div>
  );
}

export default ReportDescription;
