import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import { ClipLoader } from 'react-spinners';

import ContactInfo from '../ContactInfo';
import PaymentMethod from '../PaymentMethod';
import CreditCard from '../CreditCard';

class PostAmount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showDiscountInput: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const { firstName, lastName, stripe } = this.props;

    this.setState({
      isLoading: true
    });

    stripe
      .createToken({ name: `${firstName} ${lastName}` })
      .then(({ token }) => {
        this.props.handleTokenReceiving(token.id);
        this.props.handleZipCodeChange(token.card.address_zip);
        this.setState({ isLoading: false });
        this.props.onFormSubmit();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      firstName,
      lastName,
      paymentMethod,
      handleFirstNameChange,
      handleLastNameChange,
      handleZipCodeChange,
      handlePaymentMethodChange,
      handleNewsletterAcceptChange,
      handleVoucherChange,
      applyVoucher,
      showPaymentMethodChange,
      error,
      errorMessage,
      upgradeReport,
      submitMessage,
      usedVoucher
    } = this.props;

    const { showDiscountInput } = this.state;

    return (
      <div>
        <div className="mb3">
          <h4 className="m0 mt3">Support Method</h4>
          <PaymentMethod
            paymentMethod={paymentMethod}
            onPaymentMethodChange={handlePaymentMethodChange}
          />
        </div>
        <div className="mb3">
          <h4>Credit Card Details</h4>
          {errorMessage && <p className="color-red mb1">{errorMessage}</p>}

          <ContactInfo
            firstName={firstName}
            lastName={lastName}
            onFirstNameChange={handleFirstNameChange}
            onLastNameChange={handleLastNameChange}
            error={error}
          />

          <CreditCard hidePostalCode={paymentMethod !== 'monthly'} />

          {showDiscountInput && !usedVoucher && paymentMethod !== 'monthly' && (
            <div className="flex mt2 items-center">
              <label className="whitespace-no-wrap mr2" htmlFor="voucher_input">
                Enter voucher code:
              </label>
              <input
                type="text"
                onChange={e => this.props.handleVoucherChange(e.target.value)}
                id="voucher_input"
              />
              <button className="ml2" onClick={applyVoucher}>
                Apply
              </button>
            </div>
          )}

          {showDiscountInput && usedVoucher && paymentMethod !== 'monthly' && (
            <div className="flex mt2 items-center justify-center">
              discount code applied!
            </div>
          )}

          {!showDiscountInput && (
            <div className="flex mt2 items-center justify-center">
              <a
                className="cursor-pointer underline"
                id="apply_voucher"
                onClick={() => this.setState({ showDiscountInput: true })}
              >
                apply discount code
              </a>
            </div>
          )}
        </div>
        <div className="mt3">
          <button
            disabled={this.state.isLoading}
            className="btn btn-block btn-pill btn-primary"
            type="button"
            onClick={this.onSubmit}
          >
            {this.state.isLoading && (
              <span className="mr1">
                <ClipLoader color="#fff" size="12" />
              </span>
            )}
            {submitMessage}
          </button>
        </div>
      </div>
    );
  }
}

PostAmount.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleZipCodeChange: PropTypes.func.isRequired,
  handlePaymentMethodChange: PropTypes.func.isRequired,
  handleTokenReceiving: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  error: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  errorMessage: PropTypes.string
};

PostAmount.defaultProps = {
  error: {
    firstName: null,
    lastName: null
  }
};

export default injectStripe(PostAmount);
