import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { useFormikContext } from 'formik';
import { SUBSCRIPTION_TYPE } from './config';

function SubscriptionTypeSwitch({ disabled }) {
  const form = useFormikContext();

  const checked = form.values.donationType === SUBSCRIPTION_TYPE.ANNUAL;

  function handleChange(isChecked) {
    const donnationType = isChecked
      ? SUBSCRIPTION_TYPE.ANNUAL
      : SUBSCRIPTION_TYPE.MONTHLY;
    form.setFieldValue('donationType', donnationType);
  }

  return (
    <div className="flex mx-auto text-center items-center justify-center mb-4">
      <div className="mr-2">Monthly</div>
      <Switch
        handleDiameter={16}
        width={48}
        height={24}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        offColor="#2c8499"
        onColor="#619c43"
        uncheckedIcon={false}
        checkedIcon={false}
      />
      <div className="relative py-4 ml-2">
        <div className="premium-subscription-form__annual-saving">Save 20%</div>
        Annual
      </div>
    </div>
  );
}

SubscriptionTypeSwitch.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default SubscriptionTypeSwitch;
