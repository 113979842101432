import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportDescription from '../ReportDescription';

class TemplateSelectionStep extends Component {
  constructor(props) {
    super(props);
  }

  onAccept() {
    const { genotypeTemplateId, currentStep, nextStep, step } = this.props;

    if (genotypeTemplateId != null && currentStep === step) {
      this.props.updateFormValues(this.props);
      nextStep();
    }
  }

  render() {
    const {
      currentStep,
      genotypeTemplateId
    } = this.props;

    if (genotypeTemplateId != null && currentStep === 0) {
      if (window.location.pathname.indexOf(this.props.genotypeTemplate.slug) >= 0) {
        return <ReportDescription {...this.props.genotypeTemplate} minimumAmount={this.props.minimumAmount} onAccept={this.onAccept.bind(this)} />;
      } else {
        this.onAccept();
      }
    }
    return null;
  }
}

TemplateSelectionStep.propTypes = {
  updateFormValues: PropTypes.func.isRequired,
  genotypeTemplateId: PropTypes.number,
  nextStep: PropTypes.func,
  currentStep: PropTypes.number
};

TemplateSelectionStep.defaultProps = {
  genotypeTemplateId: null,
  nextStep: null,
  currentStep: null
};

export default TemplateSelectionStep;
