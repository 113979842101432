import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

const setIframeContent = (iframe, content) => {
  if (iframe) {
    iframe.src = "about:blank";
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(content);
    iframe.contentWindow.document.close();
  }
}

const requestPage = (iframe, markdown, setLoading) => {
  if (!iframe) return;

  setLoading(true);

  const csrfToken = document.querySelector('[name=csrf-token]').content;

  axios({
    method: 'post',
    url: '/topics-preview',
    headers: {
      'X-CSRF-TOKEN': csrfToken
    },
    data: { markdown }
  }).then(({ data }) => {
    setIframeContent(iframe, data);
    iframe.height = iframe.contentWindow.document.documentElement.scrollHeight;
    setLoading(false);
    localStorage.setItem('markdown-preview', markdown);
  });
};

const updateTopic = (updatePath, markdown) => {
  const csrfToken = document.querySelector('[name=csrf-token]').content;

  const form = document.createElement('form');

  form.method = 'post';
  form.action = updatePath;
  form.enctype = 'multipart/form-data';

  const csrfInput = document.createElement('input');
  csrfInput.type = 'hidden';
  csrfInput.name = 'authenticity_token';
  csrfInput.value = csrfToken;
  form.appendChild(csrfInput);

  const methodInput = document.createElement('input');
  methodInput.type = 'hidden';
  methodInput.name = '_method';
  methodInput.value = 'patch';
  form.appendChild(methodInput);

  const markdownInput = document.createElement('input');
  markdownInput.type = 'hidden';
  markdownInput.name = 'topic[markdown]';
  markdownInput.value = markdown;
  form.appendChild(markdownInput);

  document.body.appendChild(form);

  form.submit();
};

const LiveMd = ({ initialMarkdown, updatePath }) => {
  const iframeRef = useRef(null);
  const textAreaRef = useRef(null);
  const [markdown, setMarkdown] = useState('');
  const [loading, setLoading] = useState(false);
  const [hideTextArea, setHideTextArea] = useState(false);

  useEffect(() => {
    if (initialMarkdown) {
      setMarkdown(initialMarkdown);
    } else {
      setMarkdown(localStorage.getItem('markdown-preview'));
    }
  }, [])

  return (
    <>
      <div style={{ display: 'flex', background: '#ededed', justifyContent: 'center' }}>
        <button onClick={() => setHideTextArea(!hideTextArea)}>Toggle textarea</button> &nbsp;&nbsp;&nbsp;
        <button onClick={() => requestPage(iframeRef.current, markdown, setLoading)}>Refresh</button> &nbsp;&nbsp;&nbsp;
        {updatePath && <button onClick={() => updateTopic(updatePath, markdown)}>Save</button>}
      </div>
      <div style={{ display: 'flex' }}>
        <iframe ref={iframeRef} height={window.innerHeight - 200} width="100%" style={{ borderRight: '1px solid black' }} />
        {!hideTextArea && <textarea onChange={(e) => setMarkdown(e.target.value)} value={markdown} placeholder='Start typing here' style={{ width: '50%', padding: '5px', whiteSpace: 'pre-line' }} ref={textAreaRef} />}
      </div>
      {loading && <div style={{ position: 'absolute', top: iframeRef.current.offsetTop, left: textAreaRef.current.offsetLeft - 76.23, background: '#ededed', padding: '5px' }}>Loading...</div>}
    </>
  );
};

export default LiveMd;
