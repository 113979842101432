import React from 'react';
import { PropTypes } from 'prop-types';

class ShareForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: null,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnClick(event) { // Submit: To be completed
    event.preventDefault();

  }

  handleOnChange(event) {
    event.preventDefault();
    this.setState({ emails: this.refs.emails.value });
  }

  render() {
    return (
      <div>
        <form>
          <input ref="emails" className="my2" placeholder={this.props.placeholder} type="text" onChange={this.handleOnChange} />
          <button onClick={this.handleOnClick}>{this.props.formButtonText}</button>
        </form>
      </div>
    );
  }
}

ShareForm.propTypes = {
  formButtonText: PropTypes.string,
  placeholder: PropTypes.string,
}

ShareForm.defaultProps = {
  formButtonText: "SHARE THIS PAGE",
  placeholder: "Enter Emails",
}

export default ShareForm;
