import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { propTypeDiscount } from './propTypes';
import AmountButton from './AmountButton';

export default function AmountButtons({
  amounts,
  discounts = [],
  highlightedAmounts = []
}) {
  const form = useFormikContext();

  return amounts.map(amount => {
    const discount = discounts.find(({ from }) => from === amount);
    const selected = (discount ? discount.to : amount) === form.values.amount;
    const highlighted = highlightedAmounts.includes(amount);

    function onClick() {
      const value = get(discount, 'to', amount);
      form.setFieldValue('amount', value);
    }

    return (
      <AmountButton
        key={amount}
        amount={amount}
        annual={form.values.annual}
        onClick={onClick}
        discount={discount}
        active={selected}
        highlighted={highlighted}
        disabled={form.isSubmitting}
      />
    );
  });
}

AmountButtons.propTypes = {
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  highlightedAmounts: PropTypes.arrayOf(PropTypes.number),
  discounts: PropTypes.arrayOf(propTypeDiscount)
};
