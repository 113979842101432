import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PaymentForm from '../../PaymentForm';

const PaymentStep = ({
  currentStep,
  step,
  nextStep,
  minimumAmount,
  freeRerun,
  isSupporter,
  updateFormValues,
  stripeKey,
  errorMessage,
  defaultAmount,
  preferredSupportMethod
}) => {

  if (currentStep == step) {
    const searchParams = new URLSearchParams(window.location.search);
    const rerun = searchParams.get('rerun') === 'true' || freeRerun;

    // Skip payment step step if:
    // 1) User is a supporter (patreon or direct subscriber)
    // 2) There is no minimum amount related to the GenotypeTemplate
    //    (minimum amount is 0)
    // 3) If the user is re-running a report
    if (minimumAmount <= 0 || isSupporter || rerun) {
      updateFormValues({ amount: 0 });
      nextStep();
    }
  }

  return (
    <PaymentForm
      updateFormValues={updateFormValues}
      stripeKey={stripeKey}
      minimumAmount={minimumAmount}
      completeForm={nextStep}
      errorMessage={errorMessage}
      defaultAmount={defaultAmount}
      preferredSupportMethod={preferredSupportMethod}
      onVoucherApply={(newAmount) => (newAmount <= 0 ? nextStep() : false)}
    />
  )
}

PaymentStep.propTypes = {
  stripeKey: PropTypes.string.isRequired,
  minimumAmount: PropTypes.number.isRequired,
  freeRerun: PropTypes.bool.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  isSupporter: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  defaultAmount: PropTypes.number
};

export default PaymentStep;
