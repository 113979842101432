import React, { useRef, useState, useEffect }  from 'react';
import PropTypes            from 'prop-types';
import { useFormikContext } from 'formik';
import ReCAPTCHA            from "react-google-recaptcha";

import Field                    from '../../core/Field/Field';
import FieldPaymentCard         from '../../core/FieldPaymentCard/FieldPaymentCard';
import { propTypeDiscount }     from './propTypes';
import AmountButtons            from './AmountButtons';
import SubscriptionTypeSwitch   from './SubscriptionTypeSwitch';

import { useSubmitButtonProps, useFetchStripeToken } from './hooks';

function GenericError({ errors }) {

  if(errors.general) {
    return <p className="mb-4 text-red-500">{errors.general}</p>;
  }

  return null;
}

function Form({
  amounts,
  discounts,
  highlightedAmounts,
  countdown,
  email,
  compact = false,
  submitCopy = null,
  withConfirmation = false,
  logger,
  confirmEmail = false,
  onRecaptchaChange
}) {
  const recaptchaRef              = useRef(null);
  const form                      = useFormikContext();
  const [confirmed, setConfirmed] = useState(!withConfirmation);
  const [disabled, setDisabled]   = useState(false);

  const fetchStripeToken  = useFetchStripeToken();
  const submitButtonProps = useSubmitButtonProps(submitCopy, confirmed);

  const recaptchaSitekey = window.env.RECAPTCHA_SITE_KEY;

  useEffect(() => {
    if(recaptchaRef.current && confirmEmail) {
      recaptchaRef.current.reset();
    }
  }, [confirmEmail]);

  async function fetchTokenAndSubmit() {
    form.setSubmitting(true);
    const stripe = await fetchStripeToken();

    if (stripe.error) {
      logger.error(
        "A Stripe error occurred during premium subscription signup",
        { error: stripe.error }
      );

      form.setFieldError('stripeToken', stripe.error);
      form.setSubmitting(false);
    } else {
      const { zipCode, stripeToken } = stripe;

      if(zipCode !== null && zipCode !== undefined) {
        form.setFieldValue('zipCode', zipCode);
      }

      form.setFieldValue('stripeToken', stripeToken);

      form.handleSubmit();
    }
  };

  // We need disable/enable to be synchronous, so we've broken out the async
  // code into its own function.
  function onSubmitClick() {
    setDisabled(true);
    fetchTokenAndSubmit();
    setDisabled(false);
  }

  const renderCountdown = () => {
    const noCountDown = [
      !countdown,
      discounts.length === 0,
      form.values.amount
    ].some(Boolean);

    if (noCountDown) return null;

    const { searchParams } = new URL(window.location.href);

    const source  = decodeURIComponent(searchParams.get('cdm_id'));
    const code    = decodeURIComponent(searchParams.get('cdm_code'));

    return (
      <div className="w-full center mt-4">
        Time left to claim your 33% discount...
        <img
          className="mt-2 mx-auto"
          alt="countdown"
          src={`https://cdngif.com/${code}.gif?id=${source}`}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="premium-subscription-form_amounts pb-6">
          .
          <AmountButtons  amounts={amounts}
                          discounts={discounts}
                          highlightedAmounts={highlightedAmounts} />

          {renderCountdown()}
        </div>
        <div>
          {
            form.values.amount
            && (
              <div className="premium-subscription-form_card-details">
                <GenericError errors={ form.errors } />

                <div className="mb-4">
                  <div className="flex justify-between">
                    <label  htmlFor="email"
                            className="premium-subscription-form_field-label">
                      Email address*
                    </label>
                    {
                      form.values.currentUser
                      ? (
                        <div className="premium-subscription-form_field-label">
                          <a  className="underline"
                              href="/users/sign_out"
                              data-method="DELETE">
                            Sign out
                          </a>
                        </div>
                        )
                      : (
                          <div className="premium-subscription-form_field-label">
                            Already have an account?{' '}
                            <a className="underline" href="/users/sign_in">
                              Log in
                            </a>
                          </div>
                        )
                    }
                  </div>


                  <Field  type="email"
                          name="email"
                          disabled={disabled}
                          placeholder="Enter your email here" />

                   {
                      confirmEmail
                      && (
                        <div className="flex items-start p-2 bg-orange-100">
                          <div className="flex items-center h-5">
                            <Field  type="checkbox"
                                    name="email_confirmed" />
                          </div>
                          <div className="ml-3 text-sm">
                            <label  htmlFor="email_confirmed">
                              This is my correct email address
                            </label>
                          </div>
                        </div>
                      )
                   }       
                </div>

                <div className="flex flex-wrap -mx-2">
                  <div className="w-full px-2 md:w-1/2 mb-4">
                    <label  className="premium-subscription-form_field-label"
                            htmlFor="firstName">
                      First name*
                    </label>
                    <Field  type="text"
                            name="firstName"
                            disabled={disabled}
                            data-testid="first-name"
                            placeholder="Enter your first name" />
                  </div>
                  <div className="px-2 w-full md:w-1/2 mb-4">
                    <label  className="premium-subscription-form_field-label"
                            htmlFor="lastName">
                      Last name*
                    </label>

                    <Field  type="text"
                            name="lastName"
                            data-testid="last-name"
                            disabled={disabled}
                            placeholder="Enter your last name" />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="card-number"
                        className="premium-subscription-form_field-label">
                    Credit card number*
                  </label>

                  <FieldPaymentCard data-testid="credit-card"
                                    name="stripeToken"
                                    disabled={disabled}
                                    hidePostalCode={false} />
                </div>

                { withConfirmation
                  ? (
                      <div className="text-center my-4">
                        <label>
                          <input
                            type="checkbox"
                            className="mr-2"
                            onChange={() => setConfirmed(confirmed => !confirmed)}
                            value={confirmed}
                          />
                          I confirm the payment for a monthly subscription.
                        </label>
                      </div>
                    )
                  : null
                }

                {
                  compact
                  ? null
                  : <SubscriptionTypeSwitch disabled={disabled} />
                }

                <div className="flex justify-center mb-4">
                  <ReCAPTCHA  ref={recaptchaRef}
                              sitekey={ recaptchaSitekey } 
                              onChange={ onRecaptchaChange } />
                </div>

                <div className="flex align-center justify-center items-center flex-col">
                  <button type="submit"
                          data-testid="submit"
                          className={`button primary-alternative ${
                            compact ? 'small' : ''
                          }`}
                          onClick={onSubmitClick}
                          {...submitButtonProps}
                          disabled={ !confirmed || disabled } />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}

Form.defaultProps = {
  logger: { error: _=>_ }
}

Form.propTypes = {
  countdown:          PropTypes.bool.isRequired,
  amounts:            PropTypes.arrayOf(PropTypes.number).isRequired,
  highlightedAmounts: PropTypes.arrayOf(PropTypes.number),
  discounts:          PropTypes.arrayOf(propTypeDiscount),
  compact:            PropTypes.bool,
  submitCopy:         PropTypes.string,
  withConfirmation:   PropTypes.bool,
  email:              PropTypes.string,
  logger:             PropTypes.func,
  confirmEmail:       PropTypes.bool,
  onRecaptchaChange:  PropTypes.func.isRequired
};

export default Form;
