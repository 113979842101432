import React, { Component } from "react";
import { PropTypes } from "prop-types";

import store from "../../store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/modules/global-search";

import SearchResults from "./SearchResults";
import TopSearchFilters from "./TopSearchFilters";

import SearchSpinner from "../../utils/SearchSpinner";

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    props.loadResults(props.initialSearchResults);
    props.setFilter(props.filter);
    props.loadActiveKeywords(props.keywords);
    props.change("keywords", props.keywords);

    this.onChange = this.onChange.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getParams = this.getParams.bind(this);
  }

  onChange(key, e) {
    const { change } = this.props;
    this.props.change(key, e.target.value);
  }

  onChangeFilter(e) {
    const { change } = this.props;
    return this.props.change(e.target.id, e.target.checked);
  }

  onSubmit(e) {
    e.preventDefault();
    this.updateQueryParams();
    this.props.search(e, this.getParams());
  }

  updateQueryParams() {
    const { keywords } = this.props;
    const uri = `${location.protocol}//${location.host}${location.pathname}?q=${keywords}`;
    history.pushState({}, document.title, uri);
  }

  doSearch(paramsObj) {
    const { search } = this.props;
    return e => {
      if (e.keyCode == 13 || !e.keyCode) {
        search(e, paramsObj);
      }
    };
  }

  getParams() {
    const { filter, keywords } = this.props;
    return { keywords, filter };
  }

  getLinkText(type) {
    let linkText;

    switch (type) {
      case "soundcloud":
        return "Listen now";
      case "youtube":
        return "Watch now";
      default:
        return "Read more";
    }
  }

  getNumOfResults() {
    const { searchResults } = this.props;
    return searchResults.length;
  }

  render() {
    return (
      <section>
        <TopSearchFilters
          {...this.props}
          numOfResults={this.getNumOfResults()}
          onChangeFilter={this.onChangeFilter}
          onChange={this.onChange}
          doSearch={this.doSearch}
          getParams={this.getParams}
          onSubmit={this.onSubmit}
        />

        <SearchResults
          globalSearch={this.props}
          getLinkText={this.getLinkText}
          query={this.props.activeKeywords}
        />
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch);

export default connect(
  state => state.globalSearch,
  mapDispatchToProps
)(GlobalSearch);
