import { useState } from 'react';

export default function useOpenClose(initialValue) {
  const [isOpen, setOpen] = useState(initialValue);
  return [
    isOpen,
    {
      open: () => setOpen(true),
      close: () => setOpen(false)
    }
  ];
}
