import * as yup from 'yup';
import PropTypes from 'prop-types';

export const validationSchema = yup.object().shape({
  comment: yup.string().required('This field is required')
});

export const commentPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  indentLevel: PropTypes.number.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isModerated: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired,
  shortId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  commentingUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
  })
});
