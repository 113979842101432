import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

class TermsOfServiceStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checks: 0,
      error: null,
      isLoading: false
    };

    this.onCheck = this.onCheck.bind(this);
    this.acceptDisabled = this.acceptDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onCheck(event) {
    const { checked } = event.target;
    const { checks } = this.state;
    this.setState({ checks: checked ? checks + 1 : checks - 1 });
  }

  acceptDisabled() {
    return this.state.checks !== 3 || this.state.isLoading;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.onSubmit(e);
    this.props.nextStep();
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="clearfix terms-of-service">
        <p className="mb2">
          Please check all of the boxes below to acknowledge that you have
          accepted our conditions in order to use our service.
        </p>

        {this.state.error && <p className="error">{this.state.error}</p>}

        <dl>
          <dt>
            <input
              type="checkbox"
              name="check1"
              id="check1"
              onChange={this.onCheck}
            />
          </dt>
          <dd>
            <label htmlFor="check1">
              You agree that this website and all of our reports
              generated are to be used for educational an information purpose only –
              <strong> FoundMyFitness does not provide medical advice.</strong>
            </label>
          </dd>

          <dt>
            <input
              type="checkbox"
              name="check2"
              id="check2"
              onChange={this.onCheck}
            />
          </dt>
          <dd>
            <label htmlFor="check2">
              You agree not to use any information from this website,
              or any of our genetic reports for healthcare decisions or
              diagnostic purposes and you should always seek the advice of a qualified
              physician or other qualified health care provider with any questions you may have
              regarding diagnosis, cure, treatment, mitigation, or prevention of any disease or other medical
              condition or impairment or the status of your health.
            </label>
          </dd>

          <dt>
            <input
              type="checkbox"
              name="check3"
              id="check3"
              onChange={this.onCheck}
            />
          </dt>
          <dd>
            <label htmlFor="check3">
              You agree to this website’s <a href="/tos" target="_blank">Terms of Use</a> and <a href="/privacy_policy" target="_blank">Privacy Policy</a>.
            </label>
          </dd>
        </dl>
        <div className="center">
          <button
            onClick={this.handleSubmit}
            disabled={this.acceptDisabled()}
            className="btn btn-pill btn-primary"
            type="button"
          >
            {this.state.isLoading && (
              <span className="mr1">
                <ClipLoader color="#fff" size="12" />
              </span>
            )}
            Accept
          </button>
        </div>
      </div>
    );
  }
}

TermsOfServiceStep.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default TermsOfServiceStep;
