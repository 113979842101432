import ahoy from 'ahoy.js';
window.ahoy = ahoy;

require.context('../../redesign2019/assets/images', true);
var componentRequireContext = require.context('redesign2019/app', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

ReactRailsUJS.detectEvents();

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)

// window.addEventListener('load', () => {
//   ReactRailsUJS.mountComponents();
// });
