import React from 'react';

export default () => (
  <div id="spinner-wrapper">
    <ul className="spinner">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
);
