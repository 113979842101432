import React from 'react';
import PropTypes from 'prop-types';

import { Manager, Reference, Popper } from 'react-popper';

function Popup({
  render,
  targetClassName,
  className,
  placement = 'auto',
  isOpen = false,
  children
}) {
  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          return (
            <span ref={ref} className={targetClassName}>
              {children}
            </span>
          );
        }}
      </Reference>
      {isOpen && (
        <Popper placement={placement} positionFixed={false}>
          {({ ref, style, placement, arrowProps }) => {
            return (
              <div
                className={`popup ${className}`}
                ref={ref}
                style={style}
                data-placement={placement}
              >
                {render()}
                <div
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  data-placement={placement}
                  className="popup_arrow"
                />
              </div>
            );
          }}
        </Popper>
      )}
    </Manager>
  );
}

Popup.propTypes = {
  render: PropTypes.func.isRequired,
  className: PropTypes.string,
  targetClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  isOpen: PropTypes.bool
};

export default Popup;
