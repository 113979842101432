import React from "react";
import Highlighter from "react-highlight-words";
import SearchSpinner from "../../../utils/SearchSpinner";

export default ({ globalSearch, getLinkText, query }) => {
  const { loading, searchResults } = globalSearch;

  if (loading) {
    return <SearchSpinner />;
  }

  if (!query) {
    return (
      <div className="search-results">
        <div className="search-result py4" key={1}>
          <h3 className="center">Let's get to it!</h3>
        </div>
      </div>
    );
  }

  if (!searchResults.length) {
    return (
      <div className="search-results">
        <div className="search-result py4" key={1}>
          <h3 className="center">No results found :(</h3>
        </div>
      </div>
    );
  }

  return (
    <div className="search-results">
      {searchResults.map((r, i) => (
        <div className="search-result center" key={i}>
          <a href={r.link}>
            <h3 className="bold">
              <Highlighter
                highlightClassName="text-highlight"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={r.title}
              />
            </h3>
          </a>
          <p>
            <Highlighter
              highlightClassName="text-highlight"
              searchWords={[query]}
              autoEscape={true}
              textToHighlight={r.text}
            />
            {r.tags && r.tags.map((tag, i) => (
              <span className="search-tag" key={i}>{tag}</span>
            ))}
          </p>
          <a className="btn btn-pill btn-primary btn-small" href={r.link}>
            {getLinkText(r.type)}
          </a>
        </div>
      ))}
      <div className="center py3">
        <button
          onClick={event => {
            event.preventDefault();
            globalSearch.loadMore(globalSearch);
          }}
          className="btn btn-pill btn-secondary"
        >
          Load More
        </button>
      </div>
    </div>
  );
};
