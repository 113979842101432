import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, ErrorMessage } from 'formik';

function CommentForm({
  disabled,
  isSubmitting,
  onCancel,
  submitText = 'Post comment'
}) {
  const disableForm = disabled || isSubmitting;

  return (
    <Form className="mt-4">
      <Field
        className="field"
        component="textarea"
        rows={3}
        style={{ resize: 'none' }}
        placeholder="Type your comment here..."
        name="comment"
        disabled={disableForm}
      />
      <ErrorMessage name="comment" component="div" className="text-red-600" />
      <div className="flex flex-row-reverse">
        <button
          type="submit"
          className="button primary-alternative primary small"
          disabled={disableForm}
        >
          {submitText}
        </button>
        {onCancel && (
          <button onClick={onCancel} className="button is-text small">
            Cancel
          </button>
        )}
      </div>
    </Form>
  );
}

CommentForm.propTypes = {
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  submitText: PropTypes.string
};

export default CommentForm;
