import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';

import { validationSchema, commentPropTypes } from './config';
import CommentsList from './CommentsList';
import CommentForm from './CommentForm';

import SubscriptionModal from '../../core/SubscriptionModal';

const initialValues = {
  comment: ''
};

export default function Comments({
  storyId,
  comments,
  currentUser,
  canPost,
  premiumSubscriptionFormProps
}) {
  const [stateComments, setCommentsState] = useState(comments);
  const [errors, setErrors] = useState([]);

  const [subModalOpen, setSubModalOpen] = useState(false);

  const handleSubmit = (values, actions) => {
    return axios
      .post('/api/v1/comments', {
        lobsters: { comment: values.comment },
        story: { id: storyId }
      })
      .then(({ data }) => {
        actions.resetForm();
        setCommentsState(data.comments);
        actions.setSubmitting(false);
        setErrors(data.errors);
      });
  };

  return (
    <div className="episode-comments" id="comments">
      {!currentUser && (
        <div>
          You must <a href="/users/sign_in">login</a> or{' '}
          <a href="/users/sign_up">register</a> to comment
        </div>
      )}
      {currentUser && !canPost && (
        <div>
          Comments are reserved for approved users and supporters. In a hurry?{' '}
          <a onClick={() => setSubModalOpen(true)} className="cursor-pointer">
            Join us to comment
          </a>
        </div>
      )}
      {
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          component={props => (
            <CommentForm disabled={!currentUser || !canPost} {...props} />
          )}
        />
      }
      {errors &&
        errors.map(error => (
          <div
            key={error}
            className="episode-comments-error"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        ))}

      <SubscriptionModal
        isOpen={subModalOpen}
        premiumSubscriptionFormProps={premiumSubscriptionFormProps}
        header={
          <span>
            Comments are reserved for approved users and supporters.
            <br />
            Join us to comment!
          </span>
        }
        submitCopy="Subscribe"
        source="comments_episode"
        description={
          <span className="text-sm">Choose your monthly level of support:</span>
        }
        onClose={() => setSubModalOpen(false)}
        onSuccess={() => (window.location = '/new-subscription-confirmation')}
      />
      <CommentsList
        comments={stateComments}
        currentUser={currentUser}
        onCommentAdd={data => {
          setCommentsState(data.comments);
          setErrors(data.errors);
        }}
        storyId={storyId}
      />
    </div>
  );
}

Comments.propTypes = {
  storyId: PropTypes.number.isRequired,
  canPost: PropTypes.bool,
  comments: PropTypes.arrayOf(commentPropTypes),
  currentUser: PropTypes.object,
  premiumSubscriptionFormProps: PropTypes.object
};
