import React from 'react';
import PropTypes from 'prop-types';

export default function AmountButton({
  amount,
  discount,
  onClick,
  active,
  highlighted,
  disabled
}) {
  return (
    <div>
      <button
        key={amount}
        data-testid="amount-button"
        className={`premium-subscription-form_amount-button ${
          active ? 'is-active' : ''
        } ${highlighted ? 'is-highlighted' : ''}`}
        onClick={onClick}
        disabled={disabled}
      >
        {discount ? (
          <React.Fragment>
            <div className="premium-subscription-form_discount-text">
              limited time
            </div>
            <span
              data-testid="discount-old-amount"
              className="premium-subscription-form_discount-old-value"
            >
              ${discount.from / 100}
            </span>
            <span data-testid="discount-new-amount">${discount.to / 100}</span>
            <div className="premium-subscription-form_discount-text">
              reduced price!
            </div>
          </React.Fragment>
        ) : (
          <span className="leading-none">
            ${amount / 100}
            {highlighted && (
              <div className="premium-subscription-form_monthly-hangout">
                Monthly Hangout
              </div>
            )}
          </span>
        )}
      </button>
    </div>
  );
}

AmountButton.propTypes = {
  highlighted: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  discount: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired
  })
};
