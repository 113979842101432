import React, { Component } from 'react';

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: this.props.goToStep > 0 ? this.props.goToStep : 0,
      numberOfSteps: this.props.children.length,
      termCheckboxesChecked: [],
    };

    this.prevStep = this.prevStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.back = this.back.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.checkTermsCheckbox = this.checkTermsCheckbox.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.goToStep >= 0) {
      nextState.step = nextProps.goToStep;
    }

    return nextState;
  }

  goToStep(step) {
    if (step < this.state.numberOfSteps || step >= 0) {
      this.setState({ step });
    }
  }

  nextStep() {
    if (this.state.step < this.state.numberOfSteps - 1) {
      this.setState({ step: this.state.step + 1 });
    }
  }

  prevStep() {
    if (this.state.step !== 0) {
      this.setState({ step: this.state.step - 1 });
    }
  }

  back() {
    window.history.back();
  }

  checkTermsCheckbox(event) {
    const target = event.target;
    const termCheckboxesChecked = this.state.termCheckboxesChecked;

    if (target.checked) {
      termCheckboxesChecked.push(target.id);
    } else {
      const index = termCheckboxesChecked.indexOf(target.id);
      if (index != -1) {
        termCheckboxesChecked.splice(index, 1);
      }
    }
  }

  renderIntroText() {
    if (this.state.step < 1) {
      return <p>{this.props.intro}</p>;
    }
  }

  renderCancelButton() {
    return (
      <button
        onClick={this.prevStep}
        className="mr4 cancel"
      >
        CANCEL
      </button>
    );
  }

  renderBackButton() {
    return (
      <button
        onClick={this.back}
        className="mr4 prev"
      >
        BACK
      </button>
    );
  }

  renderNextButton() {
    return (
      <button
        onClick={this.nextStep}
        className="mr4 next"
      >
        CONTINUE
      </button>
    );
  }

  renderButtons(name) {
    const obj = {};
    switch (name) {
      case 'connectOrUpload':
        return this.renderCancelButton();
      case 'wizardUploadScreen':
        return this.renderBackButton();
      case 'termsOfService':
        return (
          <div>
            {this.renderBackButton()}
            {this.renderNextButton()}
          </div>
        );
      default:
        console.log('No buttons were rendered for this page.');
        break;
    }
  }

  render() {
    const { children } = this.props;
    const steps = children.map((child, index) => {
      const StepComponent = child.type;
      const display = index === this.state.step ? 'block' : 'none';

      return (
        <div
          style={{ display }}
          key={`step-${child.props.name}`}
          className={`wizard-step ${child.props.name}`}
        >
          {this.props.loading && <this.props.loadingComponent name="loadingStep" />}

          {
            !this.props.loading &&
            <StepComponent
              {...child.props}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              goToStep={this.goToStep}
              currentStep={this.state.step}
              step={index}
            />
          }
        </div>
      );
    });

    return (
      <div>
        {this.renderIntroText()}
        {steps}
      </div>
    );
  }
}

export default Wizard;
