import 'url-search-params-polyfill';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventBus from 'eventbusjs';

import { OPEN_WIZARD_EVENT, WIZARD_OPENED_EVENT } from '../../events';

class OpenGeneticReportWizard extends Component {
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);

    const providerToken = searchParams.get('provider_code');
    const idFromUrl = Number(searchParams.get('genotype_template_id'));
    const freeRerun = searchParams.get('free_rerun') || false;
    const rerunReportIdFromUrl = Number(searchParams.get('rerun_report_id'));
    const coupon = searchParams.get('access_key');

    const {
      genotypeTemplateId,
      minimumAmount,
      rerunReportId
    } = this.props.wizardState;

    const templateCheck =
      this.props.wizardState.startsOpen ||
      (genotypeTemplateId ? idFromUrl === genotypeTemplateId : false);

    if (templateCheck) {
      this.open({
        ...this.props.wizardState,
        genotypeTemplateId: idFromUrl || genotypeTemplateId,
        providerToken,
        minimumAmount,
        freeRerun,
        rerunReportId,
        coupon
      });
    }
  }

  open(state) {
    const openInterval = setInterval(
      () => {
        EventBus.dispatch(OPEN_WIZARD_EVENT, this, state);
      },
      500
    );
    EventBus.addEventListener(WIZARD_OPENED_EVENT, () => clearInterval(openInterval));
  }

  render() {
    const { className, innerHTML, wizardState } = this.props;

    return (
      <span>
        <button
          className={className}
          onClick={() => {
            this.open(wizardState);
          }}
          dangerouslySetInnerHTML={{ __html: innerHTML }}
        />
      </span>
    );
  }
}

OpenGeneticReportWizard.propTypes = {
  innerHTML: PropTypes.string.isRequired,
  className: PropTypes.string,
  wizardState: PropTypes.shape({
    sessionToken: PropTypes.string,
    stripeKey: PropTypes.string.isRequired,
    minimumAmount: PropTypes.number,
    genotypeTemplateId: PropTypes.number,
    isSupporter: PropTypes.bool.isRequired,
    freeRerun: PropTypes.bool.isRequired,
    rerunReportId: PropTypes.number.isRequired,
    avgTimeToRun: PropTypes.number,
    coupon: PropTypes.string
  })
};

OpenGeneticReportWizard.defaultProps = {
  className: '',
  wizardState: {
    sessionToken: null,
    minimumAmount: 0,
    genotypeTemplateId: null,
    avgTimeToRun: 12
  }
};

export default OpenGeneticReportWizard;
