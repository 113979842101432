import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

// File maximum size: 50MB
const MAX_SIZE_BYTES = 52428800;
const MIN_SIZE_BYTES = 2097152;

const fileMaxSizeError = 'The file size should be less than 50MB.';
const fileMinSizeError = 'The file size should be more than 2MB.';
const fileFormatError = `
  This file format is not supported. Please select a data file.
`;

class UploadStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      error: null,
    };

    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.files !== this.state.files || nextState.error !== null;
  }

  displayRerunMessage () {
    let result = '';

    if (this.props.numberOfReruns == 1) {
      result = '(You currently have <b>one</b> report you can rerun for free.)'
    } else if (this.props.numberOfReruns > 1) {
      result = `(You currently have <b>${this.props.numberOfReruns}</b> reports you can rerun for free.)`
    }

    return { __html: result };
  }

  onDropAccepted(acceptedFiles) {
    if (acceptedFiles[0].size > MAX_SIZE_BYTES || acceptedFiles[0].size < MIN_SIZE_BYTES) {
      return this.onDropRejected(acceptedFiles);
    }

    this.setState({ files: acceptedFiles, error: null });
    this.props.updateFormValues({
      file: acceptedFiles[0],
    });
  }

  onDropRejected(rejectedFiles) {
    if (rejectedFiles[0].size > MAX_SIZE_BYTES) {
      this.setState({ error: fileMaxSizeError });
    } else if (rejectedFiles[0].size < MIN_SIZE_BYTES) {
      this.setState({ error: fileMinSizeError });
    } else {
      this.setState({ error: fileFormatError });
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;

      if (fileContent.includes('Ancestry,Copy,Chromosome,Start Point,End Point') ||
          rejectedFiles[0].name.includes('ancestry_composition')) {
        const error = 'This is the wrong 23andMe file. On the 23andMe website, click "<a href="https://you.23andme.com/tools/data/" target="_blank">browse raw data</a>" to download the correct zip archive.';
        this.setState({ error });
      }

      Rollbar.error("Upload Step Error - " + this.state.error, {
        error: this.state.error,
        fileSize: rejectedFiles[0].size,
        content: e.target.result.substring(0, 500)
      });
    }
    reader.readAsText(rejectedFiles[0]);
  }

  hasUploadedFiles() {
    return this.state.files.length > 0;
  }

  render() {
    return (
      <div>
        {this.hasUploadedFiles() &&
          <div className="wizard-upload-screen center">
            <h4 className="m0">Upload Your Data</h4>
            <img
              className="mx-auto"
              alt="icon-file-upload"
              src="/images/icons/file_upload.png"
              width="75"
            />

            <div className="upload-file">
              {this.state.files[0].name}
            </div>

            <h6 className="my2">
              Are you sure you want to upload this file?
            </h6>
            <div className="flex">
              <div className="mr1 flex-auto">
                <button
                  type="button"
                  className="btn btn-pill btn-block btn-secondary"
                  style={{ minWidth: 130 }}
                  onClick={() => this.setState({ files: [] })}
                >
                  UPLOAD AGAIN
                </button>
              </div>
              <div className="ml1 flex-auto">
                <button
                  type="button"
                  style={{ minWidth: 130 }}
                  className="btn btn-pill btn-block btn-primary"
                  onClick={() => { this.props.nextStep(); }}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        }
        <div
          className="wizard-upload-screen center"
          style={{ display: this.hasUploadedFiles() ? 'none' : 'block' }}
        >
          <h4 className="m0">Upload Your Data</h4>

          {this.state.error &&
            <div className="error color-red" dangerouslySetInnerHTML={{ __html: this.state.error }}>
            </div>
          }

          <Dropzone
            ref={(dropzone) => { this.dropzone = dropzone; }}
            multiple={false}
            inputProps={{ name: '[wizard]file' }}
            minSize={5}
            maxSize={MAX_SIZE_BYTES * MAX_SIZE_BYTES}
            accept="text/plain, .csv, .zip, .gz"
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.onDropAccepted}
            style={
              {
                margin: 'auto',
                padding: '1em',
                borderColor: '#a9c0d0',
              }
            }
          >
            <img className="mx-auto" alt="upload" src="/images/icons/upload.png" width="75" />
            <h6 className="my2">Drag and drop your .txt or .csv data file from 23andMe or other providers like Ancestry DNA here.</h6>
          </Dropzone>

          <div className="upload-or color-black">OR</div>
          <button
            type="button"
            onClick={() => this.dropzone.open()}
            className="btn btn-pill btn-block btn-primary"
          >
            BROWSE
          </button>
        </div>
        {this.props.isSignedIn && (
          <div
            style={{ borderTop: '1px solid #a9c0d0' }}
            className="center py1 my2"
          >
            <small>
              <a target="_blank" href="/genetic_dashboard">
                View existing reports
              </a>
            </small>
            <br />
            <small style={{ fontSize: '0.7em' }}>
              <em dangerouslySetInnerHTML={this.displayRerunMessage()}></em>
            </small>
          </div>
        )}
      </div>
    );
  }
}

UploadStep.propTypes = {
  updateFormValues: PropTypes.func.isRequired,
  numberOfReruns: PropTypes.number

};

export default UploadStep;
