import React from "react";

const showingResultsMessage = (loading, activeKeywords, numOfResults) => {
  if (!numOfResults) {
    return;
  }

  if (!loading) {
    return `showing ${numOfResults} results for ${activeKeywords}`;
  }

  return "Loading results ...";
};

export default props => {
  const {
    numOfResults,
    search,
    loading,
    activeKeywords,
    keywords,
    filter,
    onChange,
    onChangeFilter,
    doSearch,
    getParams,
    onSubmit
  } = props;

  return (
    <section className="search-filters">
      <div className="search-wrapper clearfix">
        <span className="block">
          {showingResultsMessage(loading, activeKeywords, numOfResults)}
        </span>
        <form onSubmit={onSubmit}>
          <div className="search-text-wrapper clearfix">
            <input
              type="text"
              placeholder="SEARCH"
              onChange={onChange.bind(null, "keywords")}
              value={keywords}
              autocomplete="off"
            />
            <a href="#" onClick={doSearch(getParams())}>
              <i className="fa fa-search" aria-hidden="true" />
            </a>
          </div>

          <div className="clearfix filter-controls-wrapper flex mt-4 items-center">
            <div className="col">
              <span>filter content by type</span>
            </div>
            <div className="col">
              <ul>
                <li>
                  {filter == "everything" && (
                    <input
                      id="everything"
                      name="filter"
                      type="radio"
                      checked
                      onChange={onChangeFilter}
                    />
                  )}
                  {filter == "everything" || (
                    <input
                      id="everything"
                      name="filter"
                      type="radio"
                      onChange={onChangeFilter}
                    />
                  )}
                  <label htmlFor="everything">everything</label>
                </li>
                <li>
                  {filter == "episodes" && (
                    <input
                      id="episodes"
                      name="filter"
                      type="radio"
                      checked
                      onChange={onChangeFilter}
                    />
                  )}
                  {filter == "episodes" || (
                    <input
                      id="episodes"
                      name="filter"
                      type="radio"
                      onChange={onChangeFilter}
                    />
                  )}
                  <label htmlFor="episodes">episodes</label>
                </li>
                <li>
                  {filter == "news_stories" && (
                    <input
                      id="news_stories"
                      name="filter"
                      type="radio"
                      checked
                      onChange={onChangeFilter}
                    />
                  )}
                  {filter == "news_stories" || (
                    <input
                      id="news_stories"
                      name="filter"
                      type="radio"
                      onChange={onChangeFilter}
                    />
                  )}
                  <label htmlFor="news_stories">news stories</label>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
