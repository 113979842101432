import React, { useState } from 'react';
import Popup from '../Popup';

const DEFAULT_COPY = ['Choose a monthly subscription in ', ':'];

const PremiumSubscriptionLead = ({ copy }) => {
  const [isOpen, setIsOpen] = useState(false);

  const actualCopy = copy || DEFAULT_COPY;

  return (
    <>
      <span>{actualCopy[0]}</span>
      <Popup
        isOpen={isOpen}
        render={() => (
          <div className="text-xs font-semibold text-gray-500 mt-2">
            In an effort to be as inclusive as possible, our free content is
            supported through a flexible subscription model.
          </div>
        )}
        placement="top"
      >
        <h4
          onMouseOver={() => setIsOpen(true)}
          onMouseOut={() => setIsOpen(false)}
          className={`dashed font-bold inline`}
        >
          any eligible amount
        </h4>
      </Popup>
      <span>{actualCopy[1]}</span>
    </>
  );
};

export default PremiumSubscriptionLead;
