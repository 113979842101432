import React from 'react';
import PropTypes from 'prop-types';

const ContactInfo = ({ onFirstNameChange, onLastNameChange, firstName, lastName, error }) => (
  <div className="flex flex-wrap mxn2">
    <div className="col-12 md-col-6 px2 mb2">
      <input
        type="text"
        className="m0"
        id="wizard-payment-firstname"
        value={firstName}
        onChange={e => onFirstNameChange(e.target.value)}
        placeholder="First name"
      />
      {error.firstName && (
        <div className="error">{error.firstName}</div>
      )}
    </div>
    <div className="col-12 md-col-6 px2 mb2">
      <input
        id="wizard-payment-lastname"
        type="text"
        className="m0"
        value={lastName}
        onChange={e => onLastNameChange(e.target.value)}
        placeholder="Last name"
      />
      {error.lastName && (
        <div className="error">{error.lastName}</div>
      )}
    </div>
  </div>
);

ContactInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onFirstNameChange: PropTypes.func.isRequired,
  onLastNameChange: PropTypes.func.isRequired,
  error: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired
};

export default ContactInfo;
