import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import axios                          from 'axios';

const labelClassNames = [ "block",
                          "uppercase",
                          "text-gray-600",
                          "font-tillium",
                          "mb-2"].join(" ");

const inputClassNames = [ "block",
                          "w-full",
                          "p-3",
                          "border-2",
                          "border-slate-800",
                          "focus:ring-indigo-500",
                          "focus:border-indigo-500",
                          "bg-gray-100",
                          "rounded-md"].join(" ");

const buttonClassNames = ["block",
                          "w-full",
                          "p-3",
                          "my-6",
                          "bg-slate-800",
                          "uppercase",
                          "text-white",
                          "text-center",
                          "font-tillium",
                          "rounded-md",
                          "cursor-pointer"].join(" ")

const previewSize = "223px";

const fromValue = setter => ({ target: { value }}) => setter(value);

const fetchSubregions = async (countryCode, setter) => {
  const { data }    = await axios.get(`/addresses?country=${countryCode}`);
  const subregions  = data.map(([name, value]) => ({ name, value }));
  setter(subregions);
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const TShirtForm = ({ products, countries, email: currentEmail }) => {
  const [email, setEmail]           = useState(currentEmail);
  const [product, setProduct]       = useState(products[0]);
  const { variants=[] }             = product;
  const [variant, setVariant]       = useState(variants[0]);
  const [country, setCountry]       = useState(countries[0]?.value);
  const [subregions, setSubregions] = useState([]);

  useEffect(() => { fetchSubregions(country, setSubregions) }, [country]);

  const handleProductChange = evt => {
    const { value }         = evt.target;
    const selectedProduct   = products.find(p => p.id === +value);
    const [selectedVariant] = selectedProduct?.variants;

    setProduct(selectedProduct);
    setVariant(selectedVariant);
  };

  const handleVariantChange = evt => {
    const { value }       = evt.target;
    const selectedVariant = variants.find(v => v.external_id === value);

    setVariant(selectedVariant);
  };

  const handleCountryChange = evt => {
    const { value } = evt.target;

    setCountry(value);
  };

  return (
    <div className="grid grid-cols-2 gap-4" id="t-shirt-form">
      <div className="row-span-2 col-span-2 md:col-span-1 justify-center items-center flex">
        <img  src={ variant.image }
              alt={ variant.name }
              className="border mb-4 p-2 rounded-md"
              style={{ maxWidth: previewSize, maxHeight: previewSize }} />
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_product"
                className={ labelClassNames }>Select Style:</label>
        <select name="member_order[product]"
                id="member_order_product"
                className={ inputClassNames }
                value={ product.id }
                onChange={ handleProductChange }>

          {
            products.map(p => (
              <option key={ p.id } value={ p.id }>{ p.name }</option>
            ))
          }
        </select>
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_variant"
                className={ labelClassNames }>Select Size:</label>
        <select name="member_order[variant]"
                id="member_order_variant"
                className={ inputClassNames }
                value={ variant.id }
                onChange={ handleVariantChange }>

          {
            variants.map(v => (
              <option key={ v.external_id } value={ v.external_id }>
                { v.name }
              </option>
            ))
          }
        </select>
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_recipient_name"
                className={ labelClassNames }>Recipient Name</label>
        <input  type="text"
                name="member_order[recipient_name]"
                id="member_order_recipient_name"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_country"
                className={ labelClassNames }>Country</label>
        <select name="member_order[country]"
                value={ country }
                onChange={ handleCountryChange }
                id="member_order_country"
                className={ inputClassNames }>

          {
            countries.map(c => (
              <option key={ c.value } value={ c.value }>{ c.name }</option>
            ))
          }
        </select>
      </div>

      <div className="col-span-2">
        <label  htmlFor="member_order_address_line1"
                className={ labelClassNames }>Address</label>
        <input  type="text"
                name="member_order[address_line1]"
                id="member_order_address_line_1"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2">
        <label  htmlFor="member_order_address_line2"
                className={ labelClassNames }>Address Line 2</label>
        <input  type="text"
                name="member_order[address_line2]"
                id="member_order_address_line2"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_city"
                className={ labelClassNames }>City</label>
        <input  type="text"
                name="member_order[city]"
                id="member_order_city"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_state"
                className={ labelClassNames }>State</label>
        <select name="member_order[state]"
                id="member_order_state"
                className={ inputClassNames }>
          {
            subregions.map(s => (
              <option key={ s.value } value={ s.value }>{ s.name }</option>
            ))
          }
        </select>
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_zip"
                className={ labelClassNames }>Postal Code</label>
        <input  type="text"
                name="member_order[zip]"
                id="member_order_zip"
                className={ inputClassNames } />
      </div>

      <div className="md:col-start-1 col-span-2 md:col-span-1">
        <label  htmlFor="member_order_email"
                className={ labelClassNames }>Email</label>
        <input  type="email"
                name="member_order[email]"
                value={ email }
                onChange={ fromValue(setEmail) }
                id="member_order_email"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2 md:col-span-1">
        <label  htmlFor="member_order_phone"
                className={ labelClassNames }>Phone</label>
        <input  type="tel"
                name="member_order[phone]"
                id="member_order_phone"
                className={ inputClassNames } />
      </div>

      <div className="col-span-2">
        <input  type="submit"
                className={ buttonClassNames }
                value="Request T-Shirt" />
      </div>
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TShirtForm.defaultProps = {

};

TShirtForm.propTypes = {
  products:   PropTypes.array.isRequired,
  countries:  PropTypes.array.isRequired,
  email:      PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TShirtForm;
