import React from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button';

export default function Dropdown({ label, links }) {
  return (
    <Menu className="dropdown">
      <MenuButton className="dropdown_button small whitespace-no-wrap">
        {label}
        <i aria-hidden className="ml-2 fas fa fa-chevron-down" />
      </MenuButton>
      <MenuList className="dropdown_list">
        {links.map(link => (
          <MenuLink
            key={link.url}
            href={link.url}
            
            // data-method={link.method || 'GET'}

            // Understanding the Navigation Flow and Why This Solution Works
            //
            // PROBLEM WE SOLVED:
            // The previous implementation had two issues that caused duplicate network requests:
            // 1. It included both an onClick handler and href without preventing default behavior:
            //    onClick={() => (window.location.href = link.url)}
            // 2. It included data-method={link.method || 'GET'} which caused Rails UJS to intercept the click
            //
            // This created the following problematic flow for GET requests:
            // 1. User clicks link
            // 2. onClick executes, setting window.location.href (First navigation attempt)
            // 3. href's default behavior executes (Second navigation attempt)
            // 4. Rails UJS sees data-method and tries to handle it (Potential third request)
            // Result: Multiple requests to the same URL
            //
            // CURRENT SOLUTION EXPLAINED:
            // 1. We keep the href attribute:
            //    - This maintains proper HTML semantics
            //    - Allows right-click and "Open in new tab" functionality
            //    - Provides fallback if JavaScript fails
            //
            // 2. We remove data-method completely:
            //    - Prevents Rails UJS from intercepting clicks
            //    - Allows links to behave as standard HTML links
            //    - Even DELETE requests work because Rails routes handle the method appropriately
            //
            // 3. We use onClick with event.preventDefault():
            //    onClick={(event) => {
            //      event.preventDefault();
            //      window.location.href = link.url;
            //    }}
            //    
            //    This creates the following flow:
            //    a. User clicks link
            //    b. event.preventDefault() stops the default href navigation
            //    c. window.location.href executes, causing exactly one navigation
            //    Result: Single, controlled navigation to the target URL
            //
            // WHY THIS WORKS FOR ALL REQUESTS (INCLUDING DELETE):
            // - Rails routes are set up to handle the appropriate HTTP methods
            // - The server-side logic processes the request based on the route, not the HTTP method
            // - This simplifies our client-side code while maintaining functionality
            //
            // BENEFITS OF THIS APPROACH:
            // 1. Prevents duplicate network requests
            // 2. Works consistently for all link types (GET, DELETE, etc.)
            // 3. Maintains proper HTML semantics with href
            // 4. Simpler code with fewer conditional statements
            // 5. No need to handle special cases (like Manage Subscription) differently

            className="dropdown_link"

            onClick={(event) => {
              event.preventDefault();
              window.location.href = link.url;
            }}
          >
          {link.title}
        </MenuLink>
        ))}
      </MenuList>
    </Menu>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      method: PropTypes.string
    })
  )
};
