import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { CardElement } from '@stripe/react-stripe-js';
import capitalize from 'lodash/capitalize';

export default function FieldPaymentCard({
  name,
  disabled,
  hidePostalCode,
  ...rest
}) {
  const form = useFormikContext();
  const [_field, meta, helpers] = useField(name);
  const invalid = meta.error;

  return (
    <div>
      <div
        {...rest}
        className={`field ${invalid ? 'error' : ''} ${
          form.isSubmitting ? 'opacity-50' : ''
        }`}
      >
        <CardElement
          hidePostalCode={hidePostalCode}
          onBlur={() => {
            helpers.setTouched(true);
          }}
          onChange={() => {
            helpers.setError(null);
          }}
          options={{
            disabled,
            style: {
              base: {
                color: '#425a73',
                fontFamily: 'Lato, sans-serif',
                '::placeholder': {
                  color: '#a0aec0'
                },
                cardExpiry: {
                  ':disabled': {
                    opacity: 0.5
                  }
                },
                cardCvc: {
                  ':disabled': {
                    opacity: 0.5
                  }
                },
                cardNumber: {
                  ':disabled': {
                    opacity: 0.5
                  }
                }
              }
            }
          }}
        />
      </div>
      {invalid && (
        <div className="text-sm text-red-500">{capitalize(meta.error)}</div>
      )}
    </div>
  );
}

FieldPaymentCard.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hidePostalCode: PropTypes.bool
};
