import React, { useState, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { formatRelative } from 'date-fns';
import { Formik } from 'formik';

import axios from 'axios';

import CommentForm from './CommentForm';
import { commentPropTypes } from './config';

function canDeleteOrEdit(comment, currentUser) {
  return (
    currentUser &&
    !comment.isDeleted &&
    currentUser.id === comment.commentingUser?.id
  );
}

function canReply(comment, currentUser) {
  return Boolean(currentUser) && !comment.isDeleted;
}

function CommentItem({ comment, storyId, onCommentAdd, currentUser }) {
  const [isReplying, setReplying] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const createdAt = formatRelative(new Date(comment.createdAt), new Date());
  const indent = `ml-${comment.indentLevel > 1 ? comment.indentLevel * 4 : 0}`;
  const border = comment.indentLevel > 1 ? 'pl-4 border-l-2' : 'border-0';

  const initialValues = useMemo(
    () => ({
      comment: isEditing ? comment.comment.replace(/<(.|\n)*?>/g, '') : ''
    }),
    [isEditing, isReplying]
  );

  const handleDelete = () => {
    const value = window.confirm('Are you sure you want to delete?');
    if (value) {
      axios.delete(`/api/v1/comments/${comment.id}`).then(({ data }) => {
        onCommentAdd(data);
      });
    }
  };

  const handleSubmitEdit = (values, actions) => {
    return axios
      .put(`/api/v1/comments/${comment.id}`, {
        lobsters: { comment: values.comment },
        story: { id: storyId }
      })
      .then(({ data }) => {
        actions.resetForm();
        onCommentAdd(data);
        setEditing(false);
        actions.setSubmitting(false);
      });
  };

  const handleSubmitReply = (values, actions) => {
    return axios
      .post('/api/v1/comments', {
        lobsters: { comment: values.comment, parent_comment_id: comment.id },
        story: { id: storyId }
      })
      .then(({ data }) => {
        actions.resetForm();
        onCommentAdd(data);
        setReplying(false);
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <div className={`mb-4 ${indent} ${border} border-gray-300`}>
        <div className="mt-4 mb-2 flex items-center">
          <div className="text-blue-600">
            {comment.commentingUser?.username}
          </div>
          <div className="ml-4 text-sm text-gray-500">
            {capitalize(createdAt)}
          </div>
        </div>
        <div
          key={comment.id}
          className="text-gray-700 overflow-auto"
          dangerouslySetInnerHTML={{ __html: comment.comment }}
        />
        <div className="mt-2 mb-4">
          <div className="flex">
            <a href={comment.url} target="_blank" rel="noopener noreferrer">
              <button className="text-gray-500 hover:text-gray-600 mr-4 font-normal text-sm">
                Link
              </button>
            </a>
            {canDeleteOrEdit(comment, currentUser) && (
              <>
                <button
                  onClick={() => {
                    setReplying(false);
                    setEditing(true);
                  }}
                  className="text-gray-500 hover:text-gray-600 mr-4 font-normal text-sm"
                >
                  Edit
                </button>
                <button
                  onClick={handleDelete}
                  className="text-gray-500 hover:text-gray-600 mr-4 font-normal text-sm"
                >
                  Delete
                </button>
              </>
            )}
            {canReply(comment, currentUser) && (
              <button
                onClick={() => {
                  setEditing(false);
                  setReplying(true);
                }}
                className="text-gray-500 hover:text-gray-600 mr-4 font-normal text-sm"
              >
                Reply
              </button>
            )}
          </div>
          {(isReplying || isEditing) && (
            <Formik
              initialValues={initialValues}
              onSubmit={
                isReplying
                  ? handleSubmitReply
                  : isEditing
                  ? handleSubmitEdit
                  : () => null
              }
              component={props => (
                <CommentForm
                  {...props}
                  submitText={isEditing ? 'Save' : 'Reply'}
                  onCancel={() => {
                    setEditing(false);
                    setReplying(false);
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
    </>
  );
}

CommentItem.propTypes = {
  onCommentAdd: PropTypes.func.isRequired,
  storyId: PropTypes.number.isRequired,
  comment: commentPropTypes,
  currentUser: PropTypes.object
};

export default CommentItem;
