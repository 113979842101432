import axios from "axios";
import { SEARCH_ENDPOINT } from "../../config";
import { concat } from "lodash";

const initialState = {
  searchResults: [],
  activeKeywords: "",
  filter: "everything",
  loading: false,
  page: 1
};

// Action Type Constants
export const LOADING = "LOADING";
export const CHANGE = "CHANGE";
export const SET_FILTER = "SET_FILTER";
export const LOAD_RESULTS = "LOAD_RESULTS";
export const LOAD_MORE = "LOAD_MORE";
export const LOAD_ACTIVE_KEYWORDS = "LOAD_ACTIVE_KEYWORDS";

// Links Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case LOAD_RESULTS: {
      return {
        ...state,
        searchResults: action.payload
      };
    }
    case LOAD_MORE: {
      return {
        ...state,
        searchResults: concat(state.searchResults, action.payload),
        page: state.page + 1
      };
    }
    case SET_FILTER: {
      return {
        ...state,
        filter: action.payload
      };
    }
    case LOAD_ACTIVE_KEYWORDS: {
      return {
        ...state,
        activeKeywords: action.payload
      };
    }
    case CHANGE: {
      const { key, value } = action.payload;
      if (key === "keywords") {
        return { ...state, keywords: value };
      }
      return { ...state, filter: key };
    }
    default:
      return state;
  }
};

// Action Creators
export function search(e, params) {
  e.preventDefault();
  return dispatch => {
    dispatch({ type: LOADING, payload: true });
    dispatch({ type: LOAD_ACTIVE_KEYWORDS, payload: params.keywords });
    return axios
      .post(SEARCH_ENDPOINT, {
        filter: params.filter,
        q: encodeURIComponent(params.keywords)
      })
      .then(({ data }) => {
        // Dispatch and action that sets the new results here
        dispatch({
          type: LOAD_RESULTS,
          payload: data
        });

        dispatch({
          type: LOADING,
          payload: false
        });
      });
  };
}

export function change(key, value) {
  return {
    type: CHANGE,
    payload: {
      key,
      value
    }
  };
}

export function loadActiveKeywords(payload) {
  return {
    type: LOAD_ACTIVE_KEYWORDS,
    payload
  };
}

export function loadResults(payload = []) {
  return {
    type: LOAD_RESULTS,
    payload
  };
}

export function setFilter(payload = "everything") {
  return {
    type: SET_FILTER,
    payload
  };
}

export function loadMore(params) {
  return dispatch => {
    return axios
      .post(SEARCH_ENDPOINT, {
        filter: params.filter,
        q: encodeURIComponent(params.keywords),
        page: params.page + 1
      })
      .then(({ data }) => {
        dispatch({ type: LOAD_MORE, payload: data });
      });
  };
}
