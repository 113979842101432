import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import SkipRegistration from './SkipRegistration';

import { EmailVerifier } from '../../../redesign2019/app/utils';

const emailVerifier = new EmailVerifier();

const validateEmailBeforeNextStep = async (email, nextStepFn, buttonCopy, setButtonCopy, setReplacement) => {
  try {
    await emailVerifier.verify(email);
    nextStepFn();
  } catch({ replacement }) {
    if (buttonCopy === 'CONFIRM') {
      nextStepFn();
    } else {
      if (replacement) {
        setReplacement(replacement);
        setButtonCopy('CONFIRM');
      }
    }
  }
}

const IdentificationStep = ({
  step,
  currentStep,
  authenticated,
  nextStep,
  updateFormValues,
  authenticityToken
}) => {
  const [activeMethod, setActiveMethod] = useState(null);
  const [replacement, setReplacement] = useState(null);
  const [buttonCopy, setButtonCopy] = useState('Register');

  const emailChecked = buttonCopy === 'CONFIRM';

  if (currentStep == step && authenticated) {
    nextStep();
  }

  return (
    <div className="flex wizard-login">
      <div
        id="wizard-login"
        className="col-12 md-col-4 position-relative px2 pt2"
      >
        <SignInForm
          disabled={activeMethod !== 'sign-in' && activeMethod !== null}
          onSubmit={(sessionToken, email, isSupporter) => {
            updateFormValues({ sessionToken, email, isSupporter });
            setActiveMethod('sign-in');
            nextStep();
          }}
        />
      </div>
      <div className="col-12 md-col-4 position-relative px2 border-right border-left pt2">
        <SignUpForm
          disabled={activeMethod !== 'sign-up' && activeMethod !== null && !emailChecked}
          emailHint={activeMethod == 'sign-up' ? replacement : ''}
          buttonCopy={buttonCopy}
          authenticityToken={authenticityToken}
          onSubmit={(sessionToken, email) => {
            updateFormValues({ sessionToken, email });
            setActiveMethod('sign-up');
            nextStep();
          }}
        />
      </div>
      <div className="col-12 md-col-4 position-relative px2 pt2">
        <SkipRegistration
          disabled={
            activeMethod !== 'skip-registration' && activeMethod !== null && !emailChecked
          }
          emailHint={activeMethod == 'skip-registration' ? replacement : ''}
          buttonCopy={buttonCopy}
          onSubmit={(email, isSupporter) => {
            updateFormValues({ email, isSupporter, skipRegistration: true });
            setActiveMethod('skip-registration');
            validateEmailBeforeNextStep(email, nextStep, buttonCopy, setButtonCopy, setReplacement);
          }}
        />
      </div>
    </div>
  );
}

IdentificationStep.propTypes = {
  updateFormValues: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  nextStep: PropTypes.func,
  step: PropTypes.number,
  currentStep: PropTypes.number
};

IdentificationStep.defaultProps = {
  nextStep: () => { },
  currentStep: null,
  step: null
};

export default IdentificationStep;
