import React from 'react';
import { PropTypes } from 'prop-types';

import ModalImage from 'react-modal-image';

const ImageModal = ({ smallSrc, largeSrc, alt }) => {
  return (
    <ModalImage
      small={smallSrc}
      large={largeSrc}
      alt={alt}
      imageBackgroundColor="#fff"
    />
  );
};

ImageModal.propTypes = {
  smallSrc: PropTypes.string.isRequired,
  largeSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default ImageModal;
