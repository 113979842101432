import validator from 'validator';

const isRequired = (string) => {
  const validation = string.length > 0;

  if (!validation) {
    return 'This field is required.';
  }

  return null;
};

const isEmail = (string) => {
  const validation = validator.isEmail(string);

  if (!validation) {
    return `${string} is not a valid email.`;
  }

  return null;
};

const isNotNumeric = (string) => {
  const validation = validator.isNumeric(string);

  if (validation) {
    return `Field should not contain only numbers.`;
  }

  return null;
};

export {
  isRequired,
  isEmail,
  isNotNumeric
};
