import React      from 'react';
import PropTypes  from 'prop-types';

import Modal from '../../core/Modal';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ConfirmInvalidEmailModal = ({ open, email, onConfirm, onDismiss }) => {
  return (
    <Modal open={ open } onClose={ onDismiss } includeCloseButton={false}>
      <h1 className="text-xl">Please confirm your email address</h1>
      <div className="my-4">
        <p>
          The email address
          <em className="font-bold underline mx-2">{ email }</em>
          appears to be invalid.
        </p>
        <p>Are you sure you want to use this email address?</p>
      </div>

      <div className="flex justify-between">
        <button className="button small minimal" onClick={ onDismiss }>
          Cancel
        </button>
        <button className="button small primary"
                onClick={ onConfirm }>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ConfirmInvalidEmailModal.defaultProps = {
  open: false
};

ConfirmInvalidEmailModal.propTypes = {
  open:       PropTypes.bool,
  email:      PropTypes.string.isRequired,
  onConfirm:  PropTypes.func.isRequired,
  onDismiss:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ConfirmInvalidEmailModal;
