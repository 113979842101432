import React from 'react';
import PropTypes from 'prop-types';

const classForBtn = isActive => {
  const base = 'btn btn-pill btn-block btn-secondary';
  return isActive ? `${base} btn-active` : base;
};

const PaymentMethod = ({ onPaymentMethodChange, paymentMethod }) => (
  <div className="flex flex-wrap mb2 mxn2">
    <div className="col-12 md-col-6 px2">
      <button
        className={classForBtn(paymentMethod === 'onetime')}
        onClick={event => {
          event.preventDefault();
          onPaymentMethodChange('onetime');
        }}
      >
        One-time Support
      </button>
    </div>
    <div className="col-12 md-hide lg-hide my1" />
    <div className="col-12 md-col-6 px2">
      <button
        className={classForBtn(paymentMethod === 'monthly')}
        onClick={event => {
          event.preventDefault();
          onPaymentMethodChange('monthly');
        }}
      >
        Monthly Subscription
      </button>
    </div>
  </div>
);

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired
};

export default PaymentMethod;
