import React from 'react';
import { shallow } from 'enzyme';

import AmountButton from './AmountButton';

describe('AmountButton', () => {
  let wrapper, props, buttonWrapper;

  const defaultProps = {
    amount: 1500,
    highlighted: false,
    active: false,
    onClick: jest.fn()
  };

  function setup(overProps = {}) {
    props = {
      ...defaultProps,
      ...overProps
    };

    wrapper = shallow(<AmountButton {...props} />);
  }

  describe('when is highlighted', () => {
    beforeAll(() => {
      setup({ highlighted: true });
    });

    it('renders button with `is-highlighted` class', () => {
      expect(wrapper).toHaveClassName('is-highlighted');
    });
  });

  describe('when is active', () => {
    beforeAll(() => {
      setup({ active: true });
    });

    it('renders button with `is-active` class', () => {
      expect(wrapper).toHaveClassName('is-active');
    });
  });

  describe('when it has discount', () => {
    beforeAll(() => {
      setup({
        discount: {
          from: defaultProps.amount,
          to: 1000
        }
      });
    });

    it('renders old value with line trough', () => {
      expect(wrapper.find('[data-testid="discount-old-amount"]')).toHaveText(
        '$15'
      );
    });

    it('renders new value', () => {
      expect(wrapper.find('[data-testid="discount-new-amount"]')).toHaveText(
        '$10'
      );
    });
  });
});
