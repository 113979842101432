import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash/get';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';

import { api } from '../../utils';
import stripe from './stripe';
import Form from './Form';
import { propTypeDiscount } from './propTypes';
import ConfirmationDialog from './ConfirmationDialog';
import { getTotalBilling } from './utils';
import PremiumSubscriptionLead from '../../core/PremiumSubscriptionLead';
import { set } from 'lodash';

const CONFIRM_EMAIL = 'confirm_email';

const schema = yup.object().shape({
  amount: yup
    .number()
    .positive()
    .integer()
    .required(),
  donationType: yup
    .mixed()
    .oneOf(['monthly', 'annual'])
    .required(),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  zipCode: yup.string().notRequired()
});

function onFailure(error, formikBag) {
  formikBag.setSubmitting(false);

  if (get(error, 'response.data.code', '') === 'already_subscriber') {
    formikBag.setFieldError(
      'email',
      'There is already a subscription using this email'
    );
  } else {
    formikBag.setFieldError(
      'general',
      get(
        error,
        'response.data.message',
        'There was an error processing your request, try again later.'
      )
    );

    if(get(error, 'response.data.errorFields', []).includes('email')) {
      return CONFIRM_EMAIL;
    }
  }
}

const DEFAULT_HEADER_COPY = 'Become a FoundMyFitness Premium Member';

export default function PremiumSubscriptionForm({
  currentUser,
  authenticityToken,
  stripePublicationKey,
  idempotencyKey,
  discounts = [],
  description,
  anyAmountDescription = [],
  highlightedAmounts = [],
  countdown = false,
  compact = false,
  redirectOnSuccess = false,
  amounts = [1500, 2500, 5000, 12500, 25000],
  header = DEFAULT_HEADER_COPY,
  submitCopy = null,
  source = 'subscribe_page',
  withConfirmation = false,
  initialAmount = null,
  onSuccess = noop
}) {
  const [showConfirmation, setShowConfirmation]   = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [confirmEmail, setConfirmEmail]           = useState(false);

  const isCurrentUserPremium = get(currentUser, 'isPremiumSubscriber', false);
  const isSubscriptionPendingCancellation = get(
    currentUser,
    'isSubscriptionPendingCancellation',
    false
  );
  const isCurrentUserSubscriber = get(currentUser, 'subscriptionAmount', 0) > 0;

  useEffect(() => {
    Rollbar.configure({
      payload: {
        person: currentUser
      },
      ignoredMessages: [/\(unknown\):\s+Script\s+error/]
    });
  }, [Rollbar, currentUser]);

  const logger = Rollbar;
  const email = currentUser ? currentUser.email : '';

  const initialValues = {
    amount: initialAmount,
    currentUser,
    email,
    firstName: '',
    lastName: '',
    stripeToken: '',
    donationType: 'monthly',
    zipCode: '',
    source
  };

  const sendPostRequest = (values, formikBag) => {
    if (showConfirmation) {
      setShowConfirmation(false);
    }

    const options = {
      authenticityToken,
      donation: { 
        ...values, 
        idempotency_key:    idempotencyKey,
        recaptcha_response: recaptchaResponse
      }
    };

    api
      .post('/premium_subscriptions', options)
      .then(({ data }) => {
        if (!compact || redirectOnSuccess) {
          window.location = '/new-subscription-confirmation';
        }

        onSuccess(data);
      })
      .catch(error => {
        logger.error('Failed to create premium subscription', { error });
        const followupAction = onFailure(error, formikBag);

        setConfirmEmail(followupAction === CONFIRM_EMAIL);
      });
  };

  const handleFormSubmit = (values, formikBag) => {
    if (getTotalBilling({ values }) > 250) {
      setShowConfirmation(true);
      return;
    }
    sendPostRequest(values, formikBag);
  };

  return (
    <Elements stripe={stripe(stripePublicationKey)}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleFormSubmit}
        validateOnBlur
      >
        <div
          className={`premium-subscription-form ${compact ? 'is-compact' : ''}`}
        >
          <div className={`text-center ${compact ? '' : 'pt-10 pb-4'}`}>
            {header && (
              <h1 className="premium-subscription-form_header">{header}</h1>
            )}
            <div className="premium-subscription-form_description">
              {description || (
                <PremiumSubscriptionLead
                  copy={
                    anyAmountDescription.length > 0
                      ? anyAmountDescription
                      : null
                  }
                />
              )}
            </div>
          </div>
          {isCurrentUserPremium && !isSubscriptionPendingCancellation ? (
            <div className="premium-subscription-form_bottom-link">
              <a
                href="/dashboard"
                data-testid="submit"
                className="button primary-alternative"
              >
                Visit Member Dashboard
              </a>
            </div>
          ) : (
            <>
              <Form
                compact={compact}
                amounts={amounts}
                discounts={discounts}
                highlightedAmounts={highlightedAmounts}
                countdown={!!countdown}
                submitCopy={submitCopy}
                withConfirmation={withConfirmation}
                email={email}
                logger={logger}
                confirmEmail={confirmEmail}
                onRecaptchaChange={setRecaptchaResponse}
              />
              <ConfirmationDialog
                open={showConfirmation}
                onCancel={() => setShowConfirmation(false)}
                onConfirm={sendPostRequest}
              />
            </>
          )}
        </div>
      </Formik>

      {isCurrentUserSubscriber && (
        <div className="premium-subscription-form_bottom-link">
          <a className="button minimal small" href="/manage_subscription">
            Manage existing subscription
          </a>
        </div>
      )}
    </Elements>
  );
}

PremiumSubscriptionForm.propTypes = {
  discounts: PropTypes.arrayOf(propTypeDiscount),
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    isPremiumSubscriber: PropTypes.bool.isRequired,
    subscriptionAmount: PropTypes.number.isRequired,
    isSubscriptionPendingCancellation: PropTypes.bool
  }),
  defaultDescription: PropTypes.string,
  highlightedAmounts: PropTypes.arrayOf(PropTypes.number),
  amounts: PropTypes.arrayOf(PropTypes.number.isRequired),
  authenticityToken: PropTypes.string.isRequired,
  stripePublicationKey: PropTypes.string.isRequired,
  source: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  countdown: PropTypes.bool,
  compact: PropTypes.bool,
  submitCopy: PropTypes.string,
  onSuccess: PropTypes.func,
  withConfirmation: PropTypes.bool
};
