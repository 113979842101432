import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';

// TODO: Re-organize Javascript to avoid reaching into specific sub-designs for
// general functionality.
import { EmailVerifier } from 'redesign2019/app/utils';

// -----------------------------------------------------
// Private Properties
// -----------------------------------------------------

const EMAIL_EXPR = /^[a-z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i

// Arbitrary value for indicating no suggestion was given on an invalid email
// address.
const MISSING_SUGGESTION  = 314159;

const isValidFormat = email => EMAIL_EXPR.test(email);
const emailVerifier = new EmailVerifier();

const verifyEmail = (value, setIsVerifying, setSuggestion, setIsValid) => {
  const call = async () => {
    setIsVerifying(true);

    try {
      await emailVerifier.verify(value);
      setIsValid(true);
    } catch({ replacement }) {
      setIsValid(false)
      setSuggestion(replacement || MISSING_SUGGESTION);
    }

    setIsVerifying(false);
  };
  call();
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const VerifyEmailInput = ({ name,
                            value,
                            placeholder,
                            className,
                            required,
                            onChange,
                            onChangeIsVerifying,
                            ...inputProps }) => {

  const [isVerifying, setIsVerifying] = useState(false);
  const [suggestion, setSuggestion]   = useState();
  const [email, setEmail]             = useState('');
  const invalidWithoutSuggestion      = suggestion === MISSING_SUGGESTION;

  const handleVerifying = (v) => {
    setIsVerifying(v);
    onChangeIsVerifying(v);
  };

  const handleChange = (evt) => {
    const { value }  = evt.target;

    setSuggestion(null);
    setEmail(value);

    if(isValidFormat(value)) {
      verifyEmail(value, handleVerifying, setSuggestion, v => onChange(evt, v));
    }
  };

  const handleClick = (evt) => {
    evt.preventDefault();
    const { value } = evt.currentTarget;

    setEmail(suggestion);
    onChange({ target: { name, value: suggestion } }, true);
    setSuggestion(null);
  }

  useEffect(() => {
    handleChange({ target: { value } });
  }, [value]);

  return (
    <div className="verify-email-input-container flex-grow w-full">
      <input  { ...inputProps }
              type="email"
              name={ name }
              value={ email }
              placeholder={ placeholder }
              className={ className }
              required={ required }
              onChange={ handleChange } />

      {
        isVerifying
        && (
          <div className="relative">
            <div className="absolute py-2 px-4 mt-2 rounded text-black bg-white shadow verify-email-input-verifying">
              Verifying email address...
            </div>
          </div>
        )
      }

      {
        invalidWithoutSuggestion
        ? (
            <div className="relative">
              <div className="absolute py-2 px-4 mt-2 rounded text-black bg-white shadow verify-email-input-accept-invalid">
                This email address is invalid.  Please use a different email address.
              </div>
            </div>
          )
        : suggestion
          &&  (
                <div className="relative">
                  <button className="absolute block py-2 px-4 mt-2 rounded text-black bg-white shadow verify-email-input-suggestion"
                          onClick={ handleClick }>
                    Did you mean <strong className="underline">{suggestion}</strong>?
                  </button>
                </div>
              )
      }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

VerifyEmailInput.defaultProps = {
  value:                '',
  required:             false,
  onChange:             _=>_,
  onChangeIsVerifying:  _=>_
};

VerifyEmailInput.propTypes = {
  name:                 PropTypes.string.isRequired,
  value:                PropTypes.string,
  placeholder:          PropTypes.string,
  className:            PropTypes.string,
  required:             PropTypes.bool,
  onChange:             PropTypes.func,
  onChangeIsVerifying:  PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default VerifyEmailInput;
