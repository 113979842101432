import React from 'react';
import { Provider } from 'react-redux';

import store from '../store';
import GlobalSearch from './GlobalSearch';

const GlobalSearchContainer = props => (
  <Provider store={store}>
    <GlobalSearch {...props} />
  </Provider>
);

export default GlobalSearchContainer;
