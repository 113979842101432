import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingStep = ({}) => {
  return (
    <p className="center">
      Loading <br/><br/><ClipLoader />
    </p>
  )
};

export default LoadingStep;
