import React from 'react';

const problemStr = 'An error occurred while processing your report:';
const waitProcessStr = 'Please wait while your report is being processed...';

const ProgressBar = ({ percentCompleted, stepText }) => {
  const styles = {
    float: 'left',
    height: '5px',
    borderRadius: '3px'
  };

  const completedStyle = {
    ...styles,
    width: `${percentCompleted}%`,
    background: '#1691d4',
  };

  const incompleteStyle = {
    ...styles,
    width: `${100 - percentCompleted}%`,
    background: '#98F3FF',
  };

  return (
    <div style={{ width: '80%' }}>
      <div style={completedStyle}>
      </div>
      <div style={incompleteStyle}>
      </div>
      <p className="text-center">{percentCompleted}%</p>
      <p>
        <small style={{ fontSize: '0.75em' }}>{stepText}</small>
      </p>
    </div>
  )
};

const ReportProcessingStep = ({ error, percentCompleted, stepText, errorHeader }) => (
  <div className="terms-of-service center">
    <p className="center">
      {error ? <div><p>{errorHeader || problemStr}</p><p className="color-red mt2" dangerouslySetInnerHTML={{ __html: error }}></p></div> : waitProcessStr}
    </p>
    {
      !error &&
      <div className="flex justify-center mt-4">
        <ProgressBar percentCompleted={percentCompleted} stepText={stepText} />
      </div>
    }

    { error &&
      <div className="center">
        <button
          className="button secondary mt2 x-small"
          onClick={() => window.location.reload()}
        >
          Close
        </button>
      </div>
    }
  </div>
);

export default ReportProcessingStep;
