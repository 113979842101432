import React from 'react';
import { shallow } from 'enzyme';

import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button';

import Dropdown from './Dropdown';

describe('Dropdown', () => {
  let wrapper;
  let props;

  const defaultProps = {
    label: 'My Account',
    links: [
      {
        title: 'Settings',
        url: '/settings'
      },
      {
        title: 'Dashboard',
        url: '/dashboard',
        method: 'GET'
      },
      {
        title: 'Sign out',
        url: '/logout',
        method: 'DELETE'
      }
    ]
  };

  function setup(overProps = {}) {
    props = {
      ...defaultProps,
      ...overProps
    };

    wrapper = shallow(<Dropdown {...props} />);
  }

  describe('when passing three links', () => {
    beforeAll(setup);

    it('renders button with label', () => {
      expect(wrapper.find(MenuButton)).toIncludeText(props.label);
    });

    it('renders three links ', () => {
      props.links.forEach((link, index) => {
        expect(wrapper.find(MenuLink).at(index)).toIncludeText(link.title);
        expect(wrapper.find(MenuLink).at(index)).toHaveProp('href', link.url);
        expect(wrapper.find(MenuLink).at(index)).toHaveProp(
          'data-method',
          link.method || 'GET'
        );
      });
    });
  });
});
