import React from 'react';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';

export default function Modal({
  onOpen,
  onClose,
  open = false,
  children = null,
  openAfterMS = null,
  includeCloseButton = true
}) {
  useEffectOnce(() => {
    if (openAfterMS) {
      setTimeout(() => {
        onOpen();
      }, openAfterMS);
    }
  }, [open, openAfterMS]);

  if (!open) return null;
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      overlayClassName="overlay"
      className="modal"
    >
      {includeCloseButton && (
        <div className="modal_close" onClick={onClose}>
          <i className="fas fa fa-times"></i>
        </div>
      )}
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  openAfterMS: PropTypes.number,
  includeCloseButton: PropTypes.bool
};
