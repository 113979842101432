import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

function SMSForm({ authenticityToken, podcastFeedToken, onSendEvent }) {
  const [{ loading, finished, failed }, setForm] = React.useState({
    loading: false,
    finished: false,
    failed: false
  });
  const [phone, handleChange] = React.useState('');
  const disabled = loading;

  const onSuccess = () =>
    setForm(state => ({
      ...state,
      loading: false,
      finished: true,
      failed: false
    }));

  const onError = () =>
    setForm(state => ({
      ...state,
      loading: false,
      finished: false,
      failed: true
    }));

  const handleClick = () => {
    // Fire the custom event if onSendEvent is defined and not null
    // onSendEvent is passed when _text_link.html.erb is rendered from _aliquot_feed_set_up_prompt_modal.html.erb
    // _text_link.html.erb is the html.erb file that renders this file (SMSForm.js)
    // Purpose: Based on logic in aliquot_feed_set_up_prompt_modal_controller.js, this displays a close button in the upper right
    //..hand corner of _aliquot_feed_set_up_prompt_modal.html.erb
    if (onSendEvent) {
      const event = new Event(onSendEvent);
      window.dispatchEvent(event);
    }

    setForm(state => ({ ...state, failed: false, loading: true }));
    axios({
      method: 'post',
      url: '/api/v1/podcast_feed_sms',
      data: {
        phone_number: `+${phone}`,
        authenticity_token: authenticityToken,
        podcast_feed_token: podcastFeedToken
      }
    })
      .then(onSuccess)
      .catch(onError);
  };

  return (
    <div>
      <div className="flex flex-wrap mb1 mb-2">
        <PhoneInput
          prefix="+"
          value={phone}
          onChange={handleChange}
          country={'us'}
          disabled={disabled}
        />
        <button
          disabled={disabled}
          onClick={handleClick}
          className="btn btn-secondary button x-small secondary react-tel-input-button"
        >
          {loading ? 'Texting...' : 'Text me!'}
        </button>
      </div>
      <small className="text-red-500 my-2 text-sm hide-empty">
        {failed ? 'Sorry, something went wrong. Try again later.' : null}
      </small>
      <small className="text-green-500 my-2 text-sm hide-empty">
        {finished ? 'Done! You should receive a text message soon.' : null}
      </small>
    </div>
  );
}

SMSForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  podcastFeedToken: PropTypes.string.isRequired,
  onSendEvent: PropTypes.string
};

SMSForm.defaultProps = {
  onSendEvent: null
};

export default SMSForm;
