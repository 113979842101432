import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import axios from 'axios';

import { isRequired, isNotNumeric } from '../../validations';
import PostAmount from './PostAmount';

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoicingFirstName: '',
      invoicingLastName: '',
      zipCode: '',
      paymentMethod:
        this.props.preferredSupportMethod == 'monthly' ? 'monthly' : 'onetime',
      amount:
        this.props.preferredSupportMethod == 'monthly'
          ? 1500
          : this.props.minimumAmount,
      stripeToken: '',
      error: {},
      errorMessage: '',
      voucher: '',
      usedVoucher: false
    };

    this.updateStateAndFormValues = this.updateStateAndFormValues.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getFormattedAmount = this.getFormattedAmount.bind(this);

    this.updateStateAndFormValues(this.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.minimumAmount != this.props.minimumAmount) {
      this.updateStateAndFormValues({
        ...this.state,
        amount: this.props.minimumAmount,
        usedVoucher: false,
        voucher: ''
      });
    }
  }

  onFormSubmit() {
    this.validate();
  }

  updateStateAndFormValues(state) {
    let amount = this.state.amount;

    if (state.paymentMethod == 'monthly') {
      amount = 1500; // min premium amount
    } else if (state.paymentMethod == 'onetime') {
      amount = this.props.minimumAmount;
    }

    this.setState({ ...state, amount });
    this.props.updateFormValues(this.state);
  }

  validate() {
    const {
      invoicingFirstName,
      invoicingLastName,
      usedVoucher,
      voucher
    } = this.state;

    let firstName = isRequired(invoicingFirstName);
    let lastName = isRequired(invoicingLastName);
    firstName = firstName || isNotNumeric(invoicingFirstName);
    lastName = lastName || isNotNumeric(invoicingLastName);

    if (firstName || lastName) {
      this.setState({ error: { firstName, lastName } });
    } else {
      this.setState({ error: {} });

      if (usedVoucher || !voucher) {
        this.props.completeForm();
      } else {
        this.applyVoucher(() => this.props.completeForm());
      }
    }
  }

  getFormattedAmount() {
    const amount = String(this.state.amount);
    return `$${amount.slice(0, -2)}.${amount.slice(-2)}`;
  }

  applyVoucher(cb) {
    axios({
      method: 'post',
      url: '/genetics/voucher',
      data: {
        code: this.state.voucher
      }
    }).then(({ data }) => {
      if (data.error == 'expired_voucher') {
        this.setState({
          errorMessage: 'The discount code you entered has expired.'
        });
      } else if (data.error == 'invalid_voucher') {
        this.setState({
          errorMessage: 'The discount code you entered was not recognized.'
        });
      } else if (data.error) {
        this.setState({
          errorMessage:
            'Your code is invalid or there was a problem applying it. Try again later'
        });
      } else {
        const errorMessage = '';
        const amount = data.newPrice;
        const usedVoucher = true;

        this.setState({ errorMessage, amount, usedVoucher });
        this.props.updateFormValues({ amount });

        if (typeof cb === 'function') {
          cb();
        }

        this.props.onVoucherApply(amount);
      }
    });
  }

  render() {
    return (
      <div>
        <input
          type="hidden"
          name="[wizard]stripe_token"
          value={this.state.stripeToken}
        />
        <input
          type="hidden"
          name="[wizard]voucher"
          value={this.state.voucher}
        />
        <StripeProvider apiKey={this.props.stripeKey}>
          <Elements>
            <PostAmount
              usedVoucher={this.state.usedVoucher}
              submitMessage={
                this.state.paymentMethod == 'onetime'
                  ? `Charge ${this.getFormattedAmount()}`
                  : `Create subscription for ${this.getFormattedAmount()}`
              }
              error={this.state.error}
              errorMessage={this.props.errorMessage || this.state.errorMessage}
              firstName={this.state.invoicingFirstName}
              lastName={this.state.invoicingLastName}
              zipCode={this.state.zipCode}
              paymentMethod={this.state.paymentMethod}
              handlePaymentMethodChange={paymentMethod =>
                this.updateStateAndFormValues({
                  voucher: '',
                  usedVoucher: false,
                  paymentMethod
                })
              }
              handleLastNameChange={invoicingLastName =>
                this.updateStateAndFormValues({ invoicingLastName })
              }
              handleFirstNameChange={invoicingFirstName =>
                this.updateStateAndFormValues({ invoicingFirstName })
              }
              handleZipCodeChange={zipCode =>
                this.updateStateAndFormValues({ zipCode })
              }
              handleTokenReceiving={stripeToken =>
                this.updateStateAndFormValues({ stripeToken })
              }
              onFormSubmit={this.onFormSubmit}
              applyVoucher={this.applyVoucher.bind(this)}
              handleVoucherChange={voucher =>
                this.updateStateAndFormValues({ voucher })
              }
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

PaymentForm.propTypes = {
  minimumAmount: PropTypes.number.isRequired,
  stripeKey: PropTypes.string.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  completeForm: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default PaymentForm;
