import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, ErrorMessage } from 'formik';
import capitalize from 'lodash/capitalize';

export default function Field({
  name,
  type = 'text',
  placeholder,
  disabled = false,
  ...rest
}) {
  const { HtmlTag, inputType } =
    type === 'textarea'
      ? {
          HtmlTag: 'textarea',
          inputType: null
        }
      : { HtmlTag: 'input', inputType: type };
  return (
    <FormikField name={name}>
      {({ field, form }) => {
        const error = form.errors[name];
        const touched = form.touched[name];
        const isInvalid = [error, touched].every(Boolean);

        return (
          <div>
            <HtmlTag
              type={inputType}
              placeholder={placeholder}
              className={`field ${isInvalid ? 'error' : ''}`}
              disabled={disabled}
              value={field.value}
              {...rest}
              {...field}
            />
            <ErrorMessage name={name}>
              {errorMessage => (
                <div className="text-sm text-red-500">
                  {capitalize(errorMessage)}
                </div>
              )}
            </ErrorMessage>
          </div>
        );
      }}
    </FormikField>
  );
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'textarea']),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  testid: PropTypes.string
};
