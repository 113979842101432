import React from 'react';
import { act } from 'react-dom/test-utils';
import { mount } from 'enzyme';
import ReactModal from 'react-modal';

import Modal from './Modal';

describe('Modal', () => {
  let wrapper;
  let props;

  const defaultProps = {
    onOpen: jest.fn(),
    onClose: jest.fn()
  };

  function setup(overProps) {
    props = {
      ...defaultProps,
      ...overProps
    };
    wrapper = mount(<Modal {...props} />);
  }

  describe('when open is undefined', () => {
    beforeAll(() => {
      setup();
    });

    it('renders nothing', () => {
      expect(wrapper).toBeEmptyRender();
    });
  });

  describe('when open is true', () => {
    beforeAll(() => {
      setup({ open: true });
    });

    it('renders ReactModal', () => {
      expect(wrapper.find(ReactModal)).toExist();
      expect(wrapper.find(ReactModal)).toHaveProp('isOpen', true);
    });
  });

  describe('when openAfterMS is 2000', () => {
    jest.useFakeTimers();

    beforeEach(() => {
      act(() => {
        setup({ openAfterMS: 2000 });
      });
    });

    afterEach(() => {
      jest.clearAllTimers();
    });

    describe('before 2000ms', () => {
      it('does not call onOpen', () => {
        expect(props.onOpen).not.toHaveBeenCalled();
      });
    });

    describe('after 2000ms', () => {
      beforeEach(() => {
        act(() => {
          jest.advanceTimersByTime(2000);
        });
        wrapper.update();
      });

      it('calls onOpen', () => {
        expect(props.onOpen).toHaveBeenCalled();
      });
    });
  });
});
