import React from 'react';
import PropTypes from 'prop-types';

import { commentPropTypes } from './config';
import CommentItem from './CommentItem';
import EmptyState from './EmptyState';

export default function CommentsList({
  comments,
  storyId,
  onCommentAdd,
  currentUser
}) {
  if (comments.length === 0) return <EmptyState />;
  return (
    <div>
      {comments.map(comment => (
        <CommentItem
          key={comment.id}
          comment={comment}
          storyId={storyId}
          onCommentAdd={onCommentAdd}
          currentUser={currentUser}
        />
      ))}
    </div>
  );
}

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(commentPropTypes),
  storyId: PropTypes.number.isRequired,
  onCommentAdd: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};
