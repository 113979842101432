import React from 'react';
import { PropTypes } from 'prop-types';
import ShareForm from './ShareForm';
import EventBus from 'eventbusjs';
import Popup from '../redesign2019/app/core/Popup';
import { OPEN_WIZARD_EVENT } from '../events';

class TooltipComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseClick = this.handleMouseClick.bind(this);
  }

  handleMouseEnter(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ active: true });
  }

  handleMouseLeave(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ active: false });
  }

  handleMouseClick(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ active: true });
    EventBus.dispatch(OPEN_WIZARD_EVENT, this, this.props.wizardState);
  }

  renderSymbol() {
    if (this.props.showSymbol) {
      return <i className="fa fa-info-circle"></i>;
    }
  }

  render() {
    const className = this.state.active
      ? this.props.hasBorder
        ? 'tooltip active hasborder'
        : 'tooltip active'
      : 'tooltip';

    let targetContainer;
    if (this.props.targetIsBlock) {
      targetContainer = (
        <p
          onClick={this.handleMouseClick}
          style={{ margin: 'auto', height: '100%', lineHeight: '65px' }}
        >
          {this.props.target} {this.renderSymbol()}
        </p>
      );
    } else if (this.props.targetIsButton && !this.props.targetIsBlock) {
      targetContainer = <div style={{ width: '15px', height: '20px' }} />;
    } else {
      targetContainer = (
        <span>
          {this.props.target} {this.renderSymbol()}
        </span>
      );
    }
    
    return (
      <span
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Popup
          isOpen={this.state.active}
          className={className}
          targetClassName="tooltip-target"
          render={() => (
            <>
              <div className="tooltip-title mb1">{this.props.title}</div>
              <div className="tooltip-content" style={{ marginBottom: '1em' }}>
                {this.props.text}
                {this.props.showShareForm ? <ShareForm /> : ''}
              </div>
              {this.props.children}
            </>
          )}
        >
          {targetContainer}
        </Popup>
      </span>
    );
  }
}

TooltipComponent.propTypes = {
  target: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  hasBorder: PropTypes.bool,
  showShareForm: PropTypes.bool,
  showSymbol: PropTypes.bool
};

TooltipComponent.defaultProps = {
  hasBorder: false,
  showShareForm: false,
  showSymbol: true
};

export default TooltipComponent;
